export class TableUtil{
  constructor(){
    this.tableClassName = `table table-bordered border-dark`
    //this.tableStyle = 'font-size:1rem;'
    this.tableStyle = null;
    this.theadClassName = 'table-dark';
    this.columnTitles = null;
    this.tableBodyRow = null;
    this.tableBodyClassName = null;
    this.tableBodyStyle = null;
  }

  getTableBodyElement(){
    const tableBody =document.createElement('tbody');
    if(this.tableBodyClassName){
      tableBody.className = this.tableBodyClassName;
    }

    if(this.tableBodyStyle){
      tableBody.style = this.tableBodyStyle;
    }

    if(this.tableBodyRow && this.tableBodyRow?.length){
      this.tableBodyRow.forEach(tRow=>{
        tableBody.appendChild(tRow);
      });
    }

    return tableBody;
  }

  getColumnTitleElement(){
    const tableHeadElem = document.createElement('thead');
    tableHeadElem.className = this.theadClassName;
    const tableHeadRow = document.createElement('tr');

    if(this.columnTitles && this.columnTitles?.length){
      this.columnTitles.forEach(titleString=>{
        const thElement = document.createElement('th');
        thElement.innerText = titleString;
        tableHeadRow.appendChild(thElement);
      });
      tableHeadElem.appendChild(tableHeadRow);
    }
    return tableHeadElem;
  }

  getTableElement(){
    const tableWrap = document.createElement('div');
    const tableElem = document.createElement('table');
    tableElem.className = this.tableClassName;
    if(this.tableStyle){
      tableElem.style = this.tableStyle;
    }

    tableElem.appendChild(this.getColumnTitleElement());
    tableElem.appendChild(this.getTableBodyElement());
    tableWrap.appendChild(tableElem);

    return tableWrap.innerHTML;
  }




}