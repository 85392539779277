import { ModelClass } from "./ModelClass";

export class Royaty extends ModelClass{
  constructor(royalty){
    super(royalty);
    this.royaltyType = null;//정산 타입 [TI:원천징수(개인), CR:현금영수증(간이과세자), II:계산서(개인사업자, 법인사업자, 면세사업자), IO:Invocie(해외사업자)]
    this.email = null;
    this.name = null; 
    this.bankCode = null;
    this.bankName = null;

    this.init(royalty);
  }

  init(royalty){
    Object.keys(this).forEach(keyName=>{
      if(royalty && royalty[keyName]){
        this[keyName] = royalty[keyName];
      }
    })
  }
}