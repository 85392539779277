/**
 * 접속 URL 정보 출력 및 파싱 작업 수행 유틸 클래스
 */
export class UrlParserUtil {

    constructor() {
        // 현재 브라우저 URL을 파싱할 때 사용 (window.location 객체 사용)
        this.url = window.location.href;
        this.urlObject = new URL(this.url); // URL 객체로 변환
    }

    // URL의 경로(pathname) 출력
    printPathname() {
        console.log(`Pathname: ${this.urlObject.pathname}`);
    }

    // URL의 검색(query) 파라미터 출력
    printSearchParams() {
        const searchParams = this.urlObject.searchParams;
        console.log('Query Parameters:');
        for (let [key, value] of searchParams.entries()) {
            console.log(`${key}: ${value}`);
        }
    }

    // URL의 해시(hash) 정보 출력
    printHash() {
        console.log(`Hash: ${this.urlObject.hash}`);
    }

    // URL의 전체 정보 출력
    printFullUrlInfo() {
        console.log(`Full URL: ${this.url}`);
        console.log(`Protocol: ${this.urlObject.protocol}`);
        console.log(`Host: ${this.urlObject.host}`);
        console.log(`Pathname: ${this.urlObject.pathname}`);
        console.log(`Search: ${this.urlObject.search}`);
        console.log(`Hash: ${this.urlObject.hash}`);
    }

    getFullUrlInfo() {
        return {
            url: `${this.url}`,
            protocol: `${this.urlObject.protocol}`,
            host: `${this.urlObject.host}`,
            pathName: `${this.urlObject.pathname}`,
            search: `${this.urlObject.search}`,
            searchParams: `${this.urlObject.searchParams}`,
            hash: `${this.urlObject.hash}`
        }
    }

    /**
     * Path 정보에 prefix 를 포함하는지 여부 체크
     * @param prefix
     */
    containsPathNamePrefix(prefix) {
        if (prefix != null && prefix) {
            const pathName = `${this.urlObject.pathname}`;
            if (pathName != null && pathName) {
                return pathName.contains(prefix);
            }
        }
        return false;
    }

    /**
     * QueryParam 정보에 prefix 를 포함하는지 여부 체크
     * @param prefix
     */
    containsQueryParamPrefix(prefix) {
        if (prefix != null && prefix) {
            // 인코딩 되어 보여지는 특수문자 디코딩 표시
            const queryParam = `${decodeURIComponent(this.urlObject.searchParams.toString())}`;
            if (queryParam != null && queryParam) {
                return queryParam.contains(prefix);
            }
        }
        return false;
    }

    /**
     * Path 정보에 Prefix 로 시작하는지 여부 체크
     * @param prefix
     */
    startsWithPathNamePrefix(prefix) {
        if (prefix != null && prefix) {
            const pathName = `${this.urlObject.pathname}`;
            if (pathName != null && pathName) {
                return pathName.startsWith(prefix);
            }
        }
        return false;
    }

    /**
     * QueryParam 정보에 Prefix 로 시작하는지 여부 체크
     * @param prefix
     */
    startsWithQueryParamPrefix(prefix) {
        if (prefix != null && prefix) {
            // 인코딩 되어 보여지는 특수문자 디코딩 표시
            const queryParam = `${decodeURIComponent(this.urlObject.searchParams.toString())}`;
            if (queryParam != null && queryParam) {
                return queryParam.startsWith(prefix);
            }
        }
        return false;
    }

    /**
     * @param prefix // 파싱 기준 접두사 (ex: /)
     * @param deletedStr // 불필요한 (제외할) 문자열 (ex: =)
     * @return parsed array
     */
    getParsedQueryParam(prefix, deletedStr) {
        if (prefix != null && prefix) {
            // 인코딩 되어 보여지는 특수문자 디코딩 표시
            let queryParam = `${decodeURIComponent(this.urlObject.searchParams.toString())}`;
            if (queryParam != null && queryParam) {
                if (deletedStr != null && deletedStr) {
                    queryParam = queryParam.replaceAll(deletedStr, '');
                }
                return queryParam.split(prefix);
            }
        }
        return [];
    }
}