export class DataUtil {
    constructor() {
        this.CURRENCY_KR = {
            CODE: 'ko-KR',
            OPTION: {style: 'currency', currency: 'KRW'}
        }
    }

    /**
     * 해당 값이 소수점 숫자일 경우에 반올림 한 값을 반환한다.
     * 665,377.8 => 665,378
     */
    roundValue = (value) => {
        if (value != null && value) {
            // 입력 값에서 쉼표를 제거
            const numericValue = !isNaN(Number(value)) ? value : value.replace(/,/g, '');
            // 숫자로 변환 시도
            const floatNumericValue = parseFloat(numericValue);

            // 유효한 숫자인지 확인
            if (!isNaN(floatNumericValue)) {
                // 반올림 수행
                let roundedValue = Math.round(floatNumericValue);
                // 반올림된 숫자를 콤마 포맷으로 변환하여 반환
                return roundedValue.toLocaleString();
            }
            // 숫자가 아니면 원래 값을 그대로 반환
            return value;
        } else {
            return value;
        }
    }

    currencyWithSymbol = (currenyOption, numbericValue) => {
        const currencyValue = (numbericValue > 0) ? numbericValue : 0;
        return new Intl.NumberFormat(currenyOption.CODE, currenyOption.OPTION).format(currencyValue);
    }

    /**
     * 반올림 한 값을 표기하도록 한다. 원본 (소수점) 숫자는 툴팁 으로 표시 / 2024.07.03
     * **/
    currencyFormatter = (numberInfo) => {
        try {
            const formatter = new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 0
            });
            const formatValue = formatter.format(numberInfo);
            return isNaN(Number(formatValue)) ? this.roundValue(numberInfo) : formatValue;
        } catch (error) {
            console.error(error);
            return numberInfo;
        }
    }

    maskingNumber = (accountNumber) => {
        const numStr = accountNumber.toString();
        if (numStr.length) {
            const length = numStr.length;
            const maskedPart = numStr.substring(0, Math.floor(length / 2)) + '*'.repeat(Math.ceil(length / 2));
            return maskedPart;
        } else {
            return '-';
        }
    }

    getFullDate(numberYYYYMMDD) {
        let fullYearMonthDay = numberYYYYMMDD;
        if (`${numberYYYYMMDD}`.length < 8) {
            fullYearMonthDay *= 100;
            fullYearMonthDay += 1;
        }

        const year = Math.floor(fullYearMonthDay / 10000);
        const month = Math.floor((fullYearMonthDay % 10000) / 100);
        const day = fullYearMonthDay % 100;

        return {
            y: year,
            m: month,
            d: day
        }
    }

    getDotYYYYMM(numberYYYYMMDD) {
        if (typeof numberYYYYMMDD == 'number') {
            const fullDate = this.getFullDate(numberYYYYMMDD);
            const formattedDate = `${fullDate.y}.${String(fullDate.m).padStart(2, '0')}`;
            return formattedDate;
        } else {
            return numberYYYYMMDD;
        }
    }

    getDotYYYYMMDD(numberYYYYMMDD) {
        if (typeof numberYYYYMMDD == 'number') {
            const fullDate = this.getFullDate(numberYYYYMMDD);
            const formattedDate = `${fullDate.y}.${String(fullDate.m).padStart(2, '0')}.${String(fullDate.d).padStart(2, '0')}`;
            return formattedDate;
        } else {
            return numberYYYYMMDD;
        }
    }

    covertNumbericDate = (numbericDate) => {
        try {
            if (typeof numbericDate == 'number') {
                if (numbericDate.toString().length === 6) {//YYYYMM
                    const year = Math.floor(numbericDate / 10000);
                    const month = Math.floor((numbericDate % 10000) / 100);
                    const day = numbericDate % 100;
                    return new Date(year, month - 1, day); // 월은 0부터 시작하므로 1을 빼줍니다
                } else if (numbericDate.toString().length === 8) {//YYYYMMDD
                    const year = Math.floor(numbericDate / 10000);
                    const month = Math.floor((numbericDate % 10000) / 100);
                    return new Date(year, month - 1); // 월은 0부터 시작하므로 1을 빼줍니다
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (excp) {
            console.error(excp);
        }
    }

    getCurretYearMonth() {
        const dateObject = new Date()
        return (dateObject.getFullYear() * 100) + (dateObject.getMonth() + 1);
    }

    checkVostokYejeonMedia(brandCode, rightId) {
        /**
         * 해당 브랜드가 보스토크 (예전미디어) 인지 여부 체크
         * **/
        if (
            (brandCode != null && brandCode && brandCode === 'VT') &&
            (rightId != null && rightId && rightId === 1348)) {
            return true;
        } else {
            return false;
        }
    }
}