import React from 'react';
import {Modal} from "../common/Modal";
import {APIConfig} from "../../constans/APIConfig";
import AxiosClient from "../../util/AxiosClient";
import {NoticeDetail} from "../../model/notice/NoticeDetail";
import {UrlParserUtil} from "../../util/UrlParserUtil";

/**
 * 공지사항 상세 컴포넌트
 */
export class NoticeDetailComponent extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            // TODO
        }
        this.swal = new Modal();
        this.urlParserUtil = new UrlParserUtil();

        this.apiConfig = new APIConfig();
        this.apiCaller = new AxiosClient(this.apiConfig.API_URL);

        this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;
    }

    componentDidMount() {
        // 현재 상태를 브라우저 히스토리에 추가하여 뒤로가기 시 popstate 이벤트가 발생하도록 함
        window.history.pushState(null, null, window.location.pathname);
        // 브라우저의 뒤로가기 이벤트 리스너 등록
        window.addEventListener('popstate', this.handlePopState);
    }

    componentWillUnmount() {
        // 컴포넌트가 언마운트될 때 이벤트 리스너 해제
        window.removeEventListener('popstate', this.handlePopState);
    }

    handlePopState = (event) => {
        // 뒤로가기 버튼 클릭 시에, 공지사항 목록으로 이동하도록 한다.
        this.switchToNoticePageType('noticeList', null);
        // 다시 히스토리 상태를 추가하여 뒤로가기 동작을 방지
        window.history.pushState(null, null, window.location.pathname);
    };

    // 공지사항 페이지 타입 변경을 상위 컴포넌트로 전달
    switchToNoticePageType = (pageType, noticeId) => {
        this.props.onPageTypeChange(pageType, noticeId);
    };

    getIconElement(iconTitle, ReactComponent) {
        return (
            <span className='icon header-icon border-radius-md'>
          <ReactComponent/>
        </span>
        );
    }

    /**
     * 공지사항 상세 정보 요청
     * @returns {null}
     */
    reqNoticeDetail() {
        const apiPath = `${this.apiConfig.NOTICE_VIEW.getPath(this.props.noticeId, this.props.rightsHolders.rightId)}`;

        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if (res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                const responseBody = res.data?.body;
                const noticeDetail = new NoticeDetail(responseBody['noticeDetail']);

                if (noticeDetail != null && noticeDetail) {
                    document.getElementById('noticeTitle').innerText = noticeDetail['title'];
                    document.getElementById('noticeRegDate').innerText = noticeDetail['regDate'];
                    document.getElementById('noticeContent').innerHTML = noticeDetail['content'];
                }
            }
        });
        return null;
    }

    copyNoticeLink(evt) {
        const host = this.urlParserUtil.getFullUrlInfo()['host'];
        let noticeUrl = '';

        if (host == null || !host) {
            noticeUrl = `https://partner.moundmedia.net?notice/view/${this.props.noticeId}`;
        } else if (host.startsWith('localhost')) {
            noticeUrl = `http://localhost:9122?notice/view/${this.props.noticeId}`;
        } else if (host.startsWith('sett-dev.moundmedia.net')) {
            noticeUrl = `http://sett-dev.moundmedia.net:9122?notice/view/${this.props.noticeId}`;
        } else if (host.startsWith('partner.moundmedia.net')) {
            noticeUrl = `https://partner.moundmedia.net?notice/view/${this.props.noticeId}`;
        } else {
            noticeUrl = `https://partner.moundmedia.net?notice/view/${this.props.noticeId}`;
        }
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        textarea.value = noticeUrl;
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.swal.alert("공지사항 링크(주소)가 복사 되었습니다.");
    }

    render() {
        const noticeDetail = this.reqNoticeDetail();

        return (<>
            <div className='row mt-3'>
                <dl className="notice_title clear_fix">
                    <dt style={{margin: 0, fontWeight: 'bold', fontSize: '0.9rem'}}>제목</dt>
                    <dd style={{margin: '0 0 0 1rem'}}>
                        <span style={{fontSize: '0.8rem', color: '#333'}}>: <span id="noticeTitle"></span></span></dd>
                </dl>

                <div className="clear_fix notice_regdate_box">
                    <span style={{float: 'left', color: '#323232', fontSize: '0.7rem'}}>작성일: <span
                        id="noticeRegDate"></span></span>

                    <div style={{float: 'right', cursor: "pointer", fontSize: '0.7rem'}}>
                        <span onClick={(e) => this.copyNoticeLink(e)}>링크 복사</span>
                    </div>
                </div>

                <div className="view_txt" id="noticeContent" style={{marginTop: '1rem'}}></div>

                <p className="btn04" style={{textAlign: 'right'}}>
                    <a style={{padding: '0.5rem 1rem', fontSize: '0.8rem'}} className="gray-badge" onClick={(e) => {
                        this.switchToNoticePageType('noticeList', null);
                    }}>목록</a>
                </p>
            </div>
        </>);
    }
}
