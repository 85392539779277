import React from 'react';
import { RevenueInfo } from './StatemetsDetails/RevenueInfo';
import { SubtractionInfo } from './StatemetsDetails/SubtractionInfo';
import { ModifiedInfo } from './StatemetsDetails/ModifiedInfo';
import { TaxInfo } from './StatemetsDetails/TaxInfo';

export class StatementsDetails extends React.Component{

  constructor(prop){
    super(prop);
  }

  render(){
    const saleRevenue = this.props.saleRevenue;
    return(<>
      <RevenueInfo 
        calculateTotalSale = {saleRevenue?.calculateTotalSale}
        getCalculateRevenue = {saleRevenue?.getCalculateRevenue}
        carryOver = {saleRevenue?.carryOverList}
      />{/* 수익집계 */}
       
      <SubtractionInfo 
        calculateContract = {saleRevenue?.calculateContract}
        takeCalculateRevenue = {saleRevenue?.takeCalculateRevenue}
        carryOver = {saleRevenue?.carryOverList}
        salesProof = {this.props?.salesProof} 
      /> {/* 차감내역*/}

      {/* 조정 내역 */}
      {saleRevenue?.adjustmentList != null && saleRevenue?.adjustmentList.length > 0 ? <ModifiedInfo adjustmentList={saleRevenue?.adjustmentList}/> : <></>}

      <TaxInfo 
        calculateTotalSale = {saleRevenue?.calculateTotalSale}
        salesProof = {this.props?.salesProof}
      />
    </>);
  }
}