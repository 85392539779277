import React from 'react';
import { DataUtil } from '../../../../util/DataUtil';
import { CalcTotalSale } from '../../../../model/SalseRevenue/CalcTotalSale';

export class RevenueAggregation extends React.Component{
  constructor(prop){
    super(prop);
    this.SUM_STD  = 999999999;
    this.dataUtil = new DataUtil();
  }

  getElemTop(){
    return(<>
      <div className='row'>
        <div className='d-flex align-items-start'>
          <div className='text-success'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
            </svg>
          </div>
          <div className='fw-bolder drag-disable'>&nbsp;수익&nbsp;</div>
          <div className='ms-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
            </svg>
          </div>
        </div>
      </div>
    </>)
  }

  getElemSummaryTable(){
    const calculateTotalSale = this.props.calculateTotalSale;
    const tableRows = [];
    let totalSumMoney = 0;

    for(let i = 0; i < calculateTotalSale.length; i++) {
       tableRows.push(
           <tr key={i}>
             <td>{calculateTotalSale[i].assetType}</td>
             <td title={calculateTotalSale[i].cntStream}>{this.dataUtil.currencyFormatter(calculateTotalSale[i].cntStream)}</td>
             <td title={calculateTotalSale[i].cntDownload}>{this.dataUtil.currencyFormatter(calculateTotalSale[i].cntDownload)}</td>
             <td title={calculateTotalSale[i].cntEtc}>{this.dataUtil.currencyFormatter(calculateTotalSale[i].cntEtc)}</td>
             <td title={calculateTotalSale[i].totalSales}>{this.dataUtil.currencyFormatter(calculateTotalSale[i].totalSales)}</td>
             <td title={calculateTotalSale[i].totalFee}>{this.dataUtil.currencyFormatter(calculateTotalSale[i].totalFee)}</td>
             <td title={calculateTotalSale[i].totalAmountPaid}>{this.dataUtil.currencyFormatter(calculateTotalSale[i].totalAmountPaid)}</td>
           </tr>
       );
       totalSumMoney += calculateTotalSale[i].totalAmountPaid;
    }

    return (<>
      <div className='card py-3 px-3 rounded-3 gray-color-e9ecef'>
        <table>
          <thead>
          <tr className='border-bottom border-black'>
            <th>구분</th>
            <th>스트리밍 수</th>
            <th>다운로드 수</th>
            <th>기타/판매 수</th>
            <th>수익</th>
            <th>수수료</th>
            <th>지급총액</th>
          </tr>
          </thead>
          <tbody>
          {tableRows}
          </tbody>
          <tbody>
          <tr className='border-top border-black'>
            <th>합계</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th title={totalSumMoney}>{this.dataUtil.currencyFormatter(totalSumMoney)}</th>
          </tr>
          </tbody>
        </table>
      </div>
    </>);
  }

  getElemSummaryRevenue() {
    let sumClassName = (this.state.totalSales > this.SUM_STD) ? 'fs-4' : 'fs-2';
    return (<>
      <div className='d-flex flex-column mt-4'>
        <div className='fw-bolder'>수익 집계</div>
      <div className='h-100 mt-1 px-1 text-center' style={{flexDirection:'initial'}}>
        <div className='w-100 py-2 px-2'>
          {this.getElemSummaryTable()}
        </div>
      </div>
      <div className='fw-light font-color-0a0a0a'>
        <sub>* 각 스트리밍 서비스사의 사정에 따라 매출 집계가 지연될 경우 다음 정산월에 반영될 수 있습니다.</sub>
      </div>
    </div>
    </>);
  }

  render(){
    const calculateTotalSale = this.props.calculateTotalSale;
    this.state = {
      cntStream: calculateTotalSale?.cntStream,
      cntDownload: calculateTotalSale?.cntDownload,
      cntEtc: calculateTotalSale?.cntEtc, 
      totalSales: calculateTotalSale?.totalSales, 
      totalFee: calculateTotalSale?.totalFee,
      totalAmountPaid: calculateTotalSale?.totalAmountPaid,
      saleMonth : calculateTotalSale?.saleMonth
    }
    return(<>
     {this.getElemTop()}{/* 타이틀 */}
     {this.getElemSummaryRevenue()}{/* 수입 집계 정보 */}
    </>);
  }
}