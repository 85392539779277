import React from 'react';
import {CommonTable} from '../../common/CommonTable';
import {Modal} from "../../common/Modal";
import {APIConfig} from "../../../constans/APIConfig";
import AxiosClient from "../../../util/AxiosClient";
import {PaginationUtil} from "../../../util/PaginationUtil";
import {TrackRevenueItem} from "../../../model/SalseRevenue/detail/TrackRevenueItem";

export class TrackSalesData extends React.Component {
    constructor(prop) {
        super(prop);
        this.commTable = new CommonTable();
        this.commTable.classData.table = 'table table-bordered table-light';
        this.commTable.classData.tableHead = 'table-dark';
        this.commTable.classData.tableBody = 'table-light table-bordered';
        this.tableHeadTitles = [
            '발매일',
            '발매사',
            // '구분',
            '앨범코드',
            '트랙코드',
            '앨범명',
            '트랙명',
            '아티스트',
            '스트리밍 수',
            '다운로드 수',
            '기타 수',
            '저작인접권료',
            '수수료',
            '권리사 정산액',
        ];

        this.commTable.tableRowkeys = [
            'saleStartDate',
            'distributor',
            // 'assetType',
            'assetCode',
            'itemCode',
            'albumName',
            'trackName',
            'artist',
            'cntStream',
            'cntDownload',
            'cntEtc',
            'sumRevenueMoney',
            'sumRevenueFee',
            'sumRevenueTotal'
        ];
        this.paginationUtil = new PaginationUtil();
        this.swal = new Modal();
        this.apiConfig = new APIConfig();
        this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
        this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;
        this.cacheMap = new Map(); // 캐싱 데이터 맵
    }

    /**
     * 총 합계 계산 API 호출 및 렌더링
     * **/
    requestTotalSum() {
        // 총합 계산 API 호출
        const apiPath = `${this.apiConfig.SALES_SERVICE_TOTAL_SUM.getPath(this.props.revenueMonth)}?searchType=TRACK`

        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                const trackRevenueList = res.data?.body["trackRevenueList"];

                if (trackRevenueList != null && trackRevenueList) {
                    const trackRevenue = trackRevenueList[0];

                    if (trackRevenue != null && trackRevenue) {
                        const footerInfo = {
                            'saleStartDate': '총 합계',
                            'cntStream': trackRevenue["cntStream"],
                            'cntDownload': trackRevenue["cntDownload"],
                            'cntEtc': trackRevenue["cntEtc"],
                            'sumRevenueMoney': trackRevenue["sumRevenueMoney"],
                            'sumRevenueFee': trackRevenue["sumRevenueFee"],
                            'sumRevenueTotal': trackRevenue["sumRevenueTotal"]
                        };
                        this.cacheMap.set("footerInfo", footerInfo);
                        this.commTable.updateFooterInfo(footerInfo);
                    }
                }
            }
        });
    }

    render() {
        const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles);
        const tableRow = this.commTable.getTableRows(this.props.revenueList, 'table-bordered');
        return (<>
            <div className='row mt-4'>
                <div className='card p-3 innerCard'>
                    <div className="px-2 clear_fix">
                        <span style={{float: 'left'}}>리스트 수 : <span
                            id="listCount">{(this.props.pagination != null && this.props.pagination) ? this.props.pagination.totalItems : 0}</span></span>

                        <div style={{float: 'right'}}>
                            <div id="pageSizeSelectWrap" className="col-md-3 ml-md-auto">
                                <div className="input-group-sm">
                                    <select id="trackPageSizeSelect" className=""
                                            style={{border: '1px solid #495057', color: '#495057'}}
                                            onChange={(e) => {
                                                this.reRenderTrackRevenue();
                                            }}>
                                        <option value="10">10개 보기</option>
                                        <option value="20">20개 보기</option>
                                        <option value="30">30개 보기</option>
                                        <option value="50">50개 보기</option>
                                        <option value="100">100개 보기</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.commTable.getTableElement(tableHeaders, tableRow, null, 'SalesDetail', this.getPagination(this, this.props.pagination))}
                </div>
            </div>
        </>);
    }

    reRenderTrackRevenue() {
        // 서버 사이드 렌더링
        const apiPath = `${this.apiConfig.SALES_SERVICE.getPath(this.props.revenueMonth)}${this.paginationUtil.getPaginationReq(null, document.getElementById("trackPageSizeSelect"))}&searchType=TRACK`;

        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                const responseBody = res.data?.body;
                const responseRevenueList = [];
                const footerInfo = this.cacheMap.get("footerInfo");

                if (responseBody?.['trackRevenueList'] && responseBody['trackRevenueList']?.length) {
                    responseBody['trackRevenueList'].forEach(revenue => {
                        responseRevenueList.push(new TrackRevenueItem(revenue))
                    });
                }

                this.commTable.replaceTableElement('SalesDetail', this.tableHeadTitles, this.commTable.tableRowkeys, responseRevenueList, footerInfo, this.getPagination(this, responseBody['paginationResponse']));

                if (footerInfo == null) {
                    this.requestTotalSum(); // 총합 집계
                }
            }
        });
    }

    /**
     * 페이지네이션 이벤트 메서드
     */
    getPagination(self, paginationObj) {
        // 리스트 수 UPDATE
        if (document.getElementById("revenueTotalCount") != null && document.getElementById("revenueTotalCount")) {
            document.getElementById("revenueTotalCount").innerText = (paginationObj != null && paginationObj) ? paginationObj["totalItems"] : 0;
        }

        return {
            totalItems: (paginationObj != null && paginationObj) ? paginationObj["totalItems"] : null,
            itemCountPerPage: (paginationObj != null && paginationObj) ? paginationObj["itemCountPerPage"] : null,
            pageCount: (paginationObj != null && paginationObj) ? paginationObj["pageCount"] : null,
            currentPage: (paginationObj != null && paginationObj) ? paginationObj["currentPage"] : null,
            onPageChange: (pageNumber) => {
                // 서버 사이드 렌더링
                const apiPath = `${this.apiConfig.SALES_SERVICE.getPath(this.props.revenueMonth)}${this.paginationUtil.getPaginationReq(pageNumber, document.getElementById("trackPageSizeSelect"))}&searchType=TRACK`;

                this.apiCaller.getRequest(apiPath, (res) => {
                    // 세션 만료 (401) 발생 시에 로그인 요청
                    if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                        this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                        return;
                    }

                    if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                        this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                        return;
                    }

                    // 200 응답일 때에만 테이블 렌더링
                    if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                        const responseBody = res.data?.body;
                        const responseRevenueList = [];
                        const footerInfo = this.cacheMap.get("footerInfo");

                        if (responseBody?.['trackRevenueList'] && responseBody['trackRevenueList']?.length) {
                            responseBody['trackRevenueList'].forEach(revenue => {
                                responseRevenueList.push(new TrackRevenueItem(revenue))
                            });
                        }

                        this.commTable.replaceTableElement('SalesDetail', this.tableHeadTitles, this.commTable.tableRowkeys, responseRevenueList, footerInfo, this.getPagination(this, responseBody['paginationResponse']));

                        if (footerInfo == null) {
                            this.requestTotalSum(); // 총합 집계
                        }
                    }
                });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidMount() {
        // 해당 페이지 Render 된 후에 전체 합계를 구하는 메서드 호출
        this.requestTotalSum();
    }

    componentDidUpdate() {
        // 해당 페이지를 벗어난 후에 호출 되는 콜백 메서드
        this.requestTotalSum();
    }
}