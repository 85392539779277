import React from 'react';
import {SpecTableComponentImpl} from "./type/SpecTableComponentImpl";
import {NoticeTableComponentImpl} from "./type/NoticeTableComponentImpl";

/**
 * 테이블 컴포넌트
 */
export class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        /**
         * state 초기화: constructor에서 props를 사용하여 state 초기화.
         */
        this.state = {
            tableType: props.tableType || null,
            titleElem: props.titleElem || [],
            tableKey: props.tableKey || [],
            tableRow: props.tableRow || [],
            tableRowKeys: props.tableRowKeys || [],
            footerInfo: props.footerInfo || null, // 테이블 최하단 금액 합계
            classData: props.classData || null,
            reRender: false, // 동적 리렌더링 여부
            pagination: props.pagination || null // 페이지네이션 정보
        };
    }

    /**
     * 아래 메서드는 이전과 현재 props를 비교하여 props의 변화에 따라 컴포넌트의 state를 동적으로 업데이트 한다.
     * CASE: (정산 월 변경)
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        // props <-> state 속성 중에 하나라도 다를 시에, setState 호출 및 업데이트 진행
        if (prevProps.tableType !== this.props.tableType ||
            prevProps.titleElem !== this.props.titleElem ||
            prevProps.tableKey !== this.props.tableKey ||
            prevProps.tableRow !== this.props.tableRow ||
            prevProps.tableRowKeys !== this.props.tableRowKeys ||
            prevProps.footerInfo !== this.props.footerInfo ||
            prevProps.classData !== this.props.classData ||
            prevProps.pagination !== this.props.pagination) {
            this.setState({
                tableType: this.props.tableType,
                titleElem: this.props.titleElem,
                tableKey: this.props.tableKey,
                tableRow: this.props.tableRow,
                tableRowKeys: this.props.tableRowKeys,
                footerInfo: this.props.footerInfo,
                classData: this.props.classData,
                reRender: false, // 동적 리렌더링 여부
                pagination: this.props.pagination
            });
        }
    }

    /**
     * 내부 상태를 업데이트하여 테이블을 리렌더링
     * @param tableType
     * @param titleElem
     * @param tableKey
     * @param tableRow
     * @param footerInfo
     * @param classData
     * @param pagination
     */
    updateTable = (tableType, titleElem, tableKey, tableRow, footerInfo, classData, pagination) => {
        this.setState({
            tableType: tableType,
            titleElem: titleElem,
            tableKey: tableKey,
            tableRow: tableRow,
            tableRowKeys: null,
            footerInfo: footerInfo,
            classData: classData,
            reRender: true,
            pagination: pagination
        });
    }

    /**
     * 테이블 데이터의 총합 계산 및 표시(FooterInfo Update)
     * **/
    updateTableFooter = (updatedFooterInfo) => {
        this.setState({
            footerInfo: updatedFooterInfo
        });
    }

    render() {
        const {tableType} = this.state;

        if (tableType != null && tableType && tableType === 'noticeList') {
            // 공지사항 테이블 컴포넌트 생성
            const noticeTableComponentImpl = new NoticeTableComponentImpl();
            return noticeTableComponentImpl.renderTableComponent(this.state);
        } else {
            // 명세서 / 수익상세 테이블 컴포넌트 생성
            const specTableComponentImpl = new SpecTableComponentImpl();
            return specTableComponentImpl.renderTableComponent(this.state)
        }
    }
}