import React from 'react';
import {CommonTable } from '../../../common/CommonTable'

export class TaxList extends React.Component{
  constructor(prop){
    super(prop);
    this.commTable = new CommonTable();
    this.state = prop.salesProof;
    this.tableHeadTitles = [
      '정산금액',
      '소득세/부가세',
      '지방세',
      '세율'
    ]

    this.commTable.tableRowkeys = [
      'paymentAmount',
      'vat',
      'residentTax',
      'taxRate'
    ]
  }

  render(){
    const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles);
    const tableRow = this.commTable.getTableRows([this.props.taxInfo]);
    return(<>
      {this.commTable.getTableElement(tableHeaders,tableRow)}
    </>);
  }
}