import { ModelClass } from "../ModelClass";

export class CalcTotalSale extends ModelClass{
  constructor(calcTotalSale) {
    super(calcTotalSale);
    this.assetType = null; // 카탈로그 타입
    this.cntStream = 0; //스트리밍 수
    this.cntDownload = 0;//다운로드 수
    this.cntEtc = 0;//기타/판매 수
    this.totalSales = 0;//매출 
    this.totalFee = 0;//수수료 
    this.totalAmountPaid = 0;//지급총액
    this.calculateMonth = 0;//정산년월
    this.saleMonth = 0;//판매월
    this.init(calcTotalSale);
  }

  init(calcTotalSale){
    Object.keys(this).forEach(keyName=>{
      switch(keyName){
        default:
          this[keyName] = (calcTotalSale?.[keyName])? calcTotalSale[keyName] : 0;
        break;
      }
    })
  }
}