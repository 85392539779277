import { CommonTotalSum } from "../../../common/CommonTotalSum";

export class TaxTotalSum extends CommonTotalSum{
  constructor(prop){
    super(prop);
    this.totalSumTitle = '세금 합계(D)';
    this.sumFontClassName = 'text-dark';
  }

  render(){
    if(!this.props.totalSum || !this.props.totalSum === 0){
      this.sumFontClassName = 'text-dark';
    }else{
      if(this.props?.royalty?.code){
        if(this.props.royalty.code === 'II' || this.props.royalty.code === 'CR'){
          this.sumFontClassName = 'text-dark';
        }else{
          this.sumFontClassName = 'text-danger';
        }
      }else{
        this.sumFontClassName = 'text-dark';
      }
    }
    return(this.getTotalSumElem());
  }
}