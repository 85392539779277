
import React from 'react';
import '../../css/common.css';
import '../../css/componet/statements/TapPanel.css';
import { StatementsDetails } from './StatementsDetails';
import { VendorSalesData } from './menu/VendorSalesData';
import { APIConfig } from '../../constans/APIConfig';
import AxiosClient from '../../util/AxiosClient';
import { SalesRevenue } from '../../model/SalesRevenue';
import { SaleSiteRevenueItem } from '../../model/SalseRevenue/detail/SaleSiteRevenueItem';
import {AlbumSalesData} from "./menu/AlbumSalesData";
import {TrackSalesData} from "./menu/TrackSalesData";
import {AlbumDigitalRevenueItem} from "../../model/SalseRevenue/detail/AlbumDigitalRevenueItem";
import {Modal} from "../common/Modal";
import {TrackRevenueItem} from "../../model/SalseRevenue/detail/TrackRevenueItem";
import {AlbumPhysicalRevenueItem} from "../../model/SalseRevenue/detail/AlbumPhysicalRevenueItem";
import {PaginationUtil} from "../../util/PaginationUtil";

export class TabPanel extends React.Component{

  constructor(props) {
      super(props);

      this.state = {
          tabType: this.props.tabType,
          shouldUpdate: false,
          revenueMonth: null,
          saleRevenue: new SalesRevenue(null),
          salesProof: null,
          revenueList: [],
          pagination: null
      }

      this.paginationUtil = new PaginationUtil();
      this.apiConfig = new APIConfig();
      this.apiCaller = new AxiosClient(this.apiConfig.API_URL);

      this.swal = new Modal();

      this.SALES_SERVICE = this.apiConfig.SALES_SERVICE;
      this.SALES_REVENUE = this.apiConfig.SALES_REVENUE;
      this.SALES_CONTRACT_PREPAID = this.apiConfig.SALES_CONTRACT_PREPAID;
      this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;
  }

  setPageType =(typeValue, clickEvent)=> {
      const targetRoot = clickEvent.target.closest('.tab-panel');
      const tabList = targetRoot.querySelectorAll('div.tab-Icons');
      tabList.forEach(tabIcon => {
          tabIcon.classList.remove('active');
      });

      clickEvent.target.classList.add('active');
      this.props.onStateChange(typeValue);
  }

  checkView() {
      let component = null;
      switch (this.state.tabType) {
          case 'S':
          default:
              component = <StatementsDetails
                  saleRevenue={this.state.saleRevenue}
                  salesProof={this.props.salesProof}
              />;
              break;

          case 'V': // 판매처별
              component = <VendorSalesData revenueList={this.state.revenueList} pagination={this.state.pagination} revenueMonth={this.state.revenueMonth}/>;
              break;

          // case 'C': // 국가별
          //     component = <CountrySalesData revenueList={this.state.revenueList} revenueMonth={this.state.revenueMonth}/>;
          //     break;

          case 'A': // 앨범별
              component = <AlbumSalesData revenueList={this.state.revenueList} pagination={this.state.pagination} revenueMonth={this.state.revenueMonth}/>;
              break;

          case 'T': // 트랙별
              component = <TrackSalesData revenueList={this.state.revenueList} pagination={this.state.pagination} revenueMonth={this.state.revenueMonth}/>;
              break;

          // case 'R': // 아티스트별
          //     component = <ArtistSalesData revenueList={this.state.revenueList} revenueMonth={this.state.revenueMonth}/>;
          //     break;
      }
      return component;
  }

  getSalesInformation =(tabType, revenueMonth , successCallBack)=> {
      let apiPath = '';

      switch (tabType) {
          case 'V': // 판매처 별
              apiPath = `${this.SALES_SERVICE.getPath(revenueMonth)}${this.paginationUtil.getPaginationReq(null, document.getElementById("vendorPageSizeSelect"))}&searchType=VENDOR`;
              break;

          // case 'C': // 국가 별
          //     apiPath = `${this.SALES_SERVICE.getPath(revenueMonth)}?searchType=COUNTRY`;
          //     break;

          case 'A': // 앨범 별 (DIGITAL | PHYSICAL)
              const assetTypeOption = document.getElementById('assetTypeOption');
              const assetType = (assetTypeOption == null || !assetTypeOption) ? 'DIGITAL' : assetTypeOption.value;
              apiPath = `${this.SALES_SERVICE.getPath(revenueMonth)}${this.paginationUtil.getPaginationReq(null, document.getElementById("albumPageSizeSelect"))}&searchType=ALBUM&assetType=${(assetType == null || !assetType) ? 'DIGITAL' : assetType}`;
              break;

          case 'T': // 트랙 별
              apiPath = `${this.SALES_SERVICE.getPath(revenueMonth)}${this.paginationUtil.getPaginationReq(null, document.getElementById("trackPageSizeSelect"))}&searchType=TRACK`;
              break;

          // case 'R': // 아티스트 별
          //     apiPath = `${this.SALES_SERVICE.getPath(revenueMonth)}?searchType=ARTIST`;
          //     break;

          default: // 명세서/그외 요청
              apiPath = `${this.SALES_REVENUE.getPath(revenueMonth)}`;
              break;
      }

      this.apiCaller.getRequest(apiPath, (response) => {
          if (successCallBack) {
              successCallBack(response);
          }
      });
  }

  componentDidMount(){//컴포넌트가 마운트 된 직후
    //console.log(" componentDidMount : LayoutSidebar");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      //갱신이 일어난 직수 호출
      //컴포넌트가 갱신되었을 떄 DOM을 조작하기 용이함
      //이전과 현재의 props를 비교하여 네트워크 요청 작업
      //console.log("prevProps, prevState > ",prevProps, prevState);
      if ((this.props?.revenueMonth !== this.state?.revenueMonth) || (this.props?.tabType !== this.state.tabType)) {
          this.getSalesInformation(this.props.tabType, this.props.revenueMonth, (res) => {
              let responseBody = null;
              let responseSaleRevenue = null;
              let responseRevenueList = null;
              let responsePagination = null;

              // 세션 만료 (401) 발생 시에 로그인 요청
              if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                  this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                  return;
              }

              if(res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                  this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                  return;
              }

              if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                  responseBody = res.data?.body;
              }

              switch (this.props.tabType) {
                  case 'V': // 판매처 별 내역
                      if (responseBody?.['saleSiteRevenueList'] && responseBody['saleSiteRevenueList']?.length) {
                          responseRevenueList = [];
                          responseBody['saleSiteRevenueList'].forEach(revenue => {
                              responseRevenueList.push(new SaleSiteRevenueItem(revenue));
                          });
                      }
                      break;

                  case 'A': // 앨범 별 (DIGITAL | PHYSICAL)
                      if (responseBody?.['albumDigitalRevenueList'] && responseBody['albumDigitalRevenueList']?.length) {
                          responseRevenueList = [];
                          responseBody['albumDigitalRevenueList'].forEach(revenue => {
                              responseRevenueList.push(new AlbumDigitalRevenueItem(revenue));
                          });
                      } else if (responseBody?.['albumPhysicalRevenueList'] && responseBody['albumPhysicalRevenueList']?.length) {
                          responseRevenueList = [];
                          responseBody['albumPhysicalRevenueList'].forEach(revenue => {
                              responseRevenueList.push(new AlbumPhysicalRevenueItem(revenue));
                          });
                      }
                      break;

                  case 'T': // 트랙 별
                      if (responseBody?.['trackRevenueList'] && responseBody['trackRevenueList']?.length) {
                          responseRevenueList = [];
                          responseBody['trackRevenueList'].forEach(revenue => {
                              responseRevenueList.push(new TrackRevenueItem(revenue));
                          });
                      }
                      break;

                  case 'C': // 국가 별
                      break;

                  case 'R': // 아티스트 별
                      break;

                  default: // 명세서/그외
                      responseSaleRevenue = new SalesRevenue(responseBody);
                      break;
              }

              if(responseBody?.['paginationResponse']) {
                  responsePagination = responseBody['paginationResponse'];
              }

              this.setState({
                  tabType: this.props.tabType,
                  revenueMonth: this.props.revenueMonth,
                  salesProof: this.props.salesProof,
                  saleRevenue: responseSaleRevenue,
                  revenueList: responseRevenueList,
                  pagination : responsePagination
              });
          })
      }
  }

  componentWillUnmount(){
    //컴포넌트가 마운트 해제되어 제거되기 직전에 호출
    //타이머 제거, 네트워크 요청 취소, 구독 해제등 정리작업 수행
    console.warn("componentWillUnmount : LayoutSidebar");
  }

  shouldComponentUpdate(nextProps, nextState) {
      /**
       // props 또는 state가 새로운 값으로 갱신되어 렌더링 발생전 호출
       console.log("[shouldComponentUpdate] nextProps, nextState ", nextProps, nextState);
       console.debug("shouldComponentUpdate : LayoutSidebar", nextProps, nextState);
       if (nextProps['salesProof']) {
       this.getSalesInformation(this.state.pageType, nextProps['salesProof'].revenueMonth);
       } else {
       this.setState({responseData: null});
       }
       **/
      return true;
  }

  render() {
    const showingTabNum = 2;
    const buttonDefaultClassName = `col-sm-${12 / showingTabNum} text-center tab-button`;
    const titleClassName = `tab-Icons rounded-3 bg-secondary h-100 text-white mh-100 fs-5 p-3 ms-cursor-over`;
    return (
        <>
          <div className='row mt-4'>
            <div className='card p-3'>
              <div className='row mt-2 tab-panel'>
                <div className="tab-container">
                  <span className={buttonDefaultClassName}>
                    <div className={`${titleClassName} active`} onClick={(e) => this.setPageType('S', e)}>명세서</div>
                  </span>

                  <span className={buttonDefaultClassName}>
                    <div className={titleClassName} onClick={(e) => this.setPageType('V', e)}>판매처 별 내역</div>
                  </span>

                  {/* TODO 국가 별 데이터는 현재 정산 분배 시에 수집 안함 */}
                  {/*<span className={buttonDefaultClassName}>*/}
                  {/*  <div className={titleClassName} onClick={(e) => this.setPageType('C', e)}>국가 별 내역</div>*/}
                  {/*</span>*/}

                  <span className={buttonDefaultClassName}>
                    <div className={titleClassName} onClick={(e) => this.setPageType('A', e)}>앨범 별 내역</div>
                  </span>

                  <span className={buttonDefaultClassName}>
                    <div className={titleClassName} onClick={(e) => this.setPageType('T', e)}>트랙 별 내역</div>
                  </span>

                  {/* TODO 아티스트의 경우, 아티스트명이 겹치는 경우도 많고, 예외 케이스가 있어 추후에 요청이 들어오면, 논의 후에 개발 하도록 한다. */}
                  {/*<span className={buttonDefaultClassName}>*/}
                  {/*  <div className={titleClassName} onClick={(e) => this.setPageType('R', e)}>아티스트 별 내역</div>*/}
                  {/*</span>*/}
                </div>
              </div>
            </div>
          </div>
          {this.checkView()}
        </>
    );
  }
}