import {ModelClass} from "../ModelClass";

export class CalcCarryOver extends ModelClass {
    constructor(calcCarryOver) {
        super(calcCarryOver);
        this.revenueMonth = null; // 정산 월
        this.carryOverMemo = null; // 이월 사유
        this.forwardedAmount = 0; // 전기 이월 잔액
        this.carriedOverAmount = 0; // 차기 이월 잔액
        this.init(calcCarryOver);
    }

    init(calcCarryOver) {
        Object.keys(this).forEach(keyName => {
            switch (keyName) {
                case 'revenueMonth':
                    if (calcCarryOver?.[keyName]) {
                        this[keyName] = this.getYearMonthStr(calcCarryOver[keyName], this.fmt_yyyyMM_dot);
                    }
                    break;

                case 'forwardedAmount':
                case 'carriedOverAmount':
                    this[keyName] = this.getNumberWithDecimaPoint(calcCarryOver[keyName], 2);
                    break;

                default:
                    if (calcCarryOver?.[keyName]) {
                        this[keyName] = calcCarryOver[keyName];
                    }
                    break;
            }
        })
    }
}