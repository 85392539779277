import { ModelClass } from "../ModelClass";

export class CalcRevenue extends ModelClass{
  constructor(calcRevenue){
    super(calcRevenue);
    this.revenueMonth = 0;//분배년월
    this.cntStream = 0;//스트리밍 수
    this.cntDownload = 0;//다운로드 수
    this.cntEtc = 0;//기타/판매 수
    this.rightName = null;//권리자명 (분배한 권리자명)
    this.rightIdFrom = null;//분배한 권리자 ID
    this.revenueRate = 0;//분배율(%)
    this.sumRevenueMoney = 0;//분배금액
    this.assetNameKo = null;//앨범 이름 국내용
    this.itemNameKo = null;//트랙 이름 국내용
    this.init(calcRevenue);
  }

  init(calcRevenue){
    Object.keys(this).forEach(keyName=>{
      switch(keyName){

        case 'revenueMonth':
          if(calcRevenue?.[keyName]){
            this[keyName] = this.getYearMonthStr(calcRevenue[keyName] , this.fmt_yyyyMM_dot);
          }
        break;

        case 'sumRevenueMoney':
          if(calcRevenue?.[keyName]){
            this[keyName] = this.getNumberWithDecimaPoint(calcRevenue?.[keyName], 2);
          }
        break;

        case 'revenueRate':
          if(calcRevenue?.[keyName]){
            this[keyName] = this.getRateValue(calcRevenue[keyName]);
          }
        break;

        default:
          if(calcRevenue?.[keyName]){
            this[keyName] = calcRevenue[keyName];
          }
        break;
      }
    });
  }



}