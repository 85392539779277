import React from "react";
import CommonPagination from "../../common/CommonPagination";
import {DataUtil} from "../../../util/DataUtil";

/**
 * 명세서 / 수익상세 페이지 내 테이블 컴포넌트 유틸
 */
export class SpecTableComponentImpl {
    constructor() {
        this.dataUtil = new DataUtil();
    }

    renderTableComponent(state) {
        const {
            tableType,
            titleElem,
            tableKey,
            tableRow,
            tableRowKeys,
            footerInfo,
            classData,
            reRender,
            pagination
        } = state;

        if (reRender === false) {
            /* 푸터 (합계) 정보 계산 */
            const footerList = [];

            if (tableType != null && tableType && tableType === 'SalesDetail') {
                /** 수익 상세 페이지 내 합계 정보 **/
                if ((footerInfo != null && footerInfo) && (tableRow != null && tableRow.length > 0)) {
                    const tdList = [];
                    const tableKeyMap = (tableRowKeys != null && tableRowKeys) ? new Map(tableRowKeys.map(key => [key, key])) : {};

                    for (const [key, value] of tableKeyMap.entries()) {
                        const mapValue = footerInfo[key];

                        if (mapValue == null) {
                            tdList.push(<td style={{backgroundColor: '#c6c6c6'}}></td>);
                        } else {
                            const numberFormatValue = (typeof mapValue === 'number') ? this.dataUtil.currencyFormatter(mapValue) : mapValue;
                            tdList.push(<td style={{backgroundColor: '#c6c6c6'}}
                                            title={mapValue}>{numberFormatValue}</td>);
                        }
                    }

                    footerList.push(
                        <tfoot className={classData.tableFooter}>
                        <tr style={{borderColor: '#ffffff', fontWeight: 'bold'}}>{tdList}</tr>
                        </tfoot>
                    );
                }
            } else {
                // 명세서 탭 내 합계 정보
                if (footerInfo != null && footerInfo) {
                    footerList.push(
                        <tfoot className={classData.tableFooter}>
                        <tr>
                            <td className='text-center'>{footerInfo.title}</td>
                            <td colSpan={tableRowKeys.length - 2} className='text-end'></td>
                            <td colSpan={1} className='text-center'
                                title={footerInfo.value}>{this.dataUtil.currencyFormatter(footerInfo.value)}</td>
                        </tr>
                        </tfoot>);
                }
            }

            return (
                <>
                    <div className={classData.tableWrap} id={'tableWrap'}>
                        <table className={classData.table}>
                            <thead className={classData.tableHead}>
                            {titleElem}
                            </thead>
                            <tbody className={classData.tableBody}>
                            {tableRow}
                            </tbody>
                            {footerList}
                        </table>
                    </div>

                    {
                        (pagination != null && pagination) ?
                            <CommonPagination
                                totalItems={pagination.totalItems}
                                itemCountPerPage={pagination.itemCountPerPage}
                                pageCount={pagination.pageCount}
                                currentPage={pagination.currentPage}
                                onPageChange={pagination.onPageChange}
                            /> : <></>
                    }
                </>
            );
        } else {
            /**
             * setState 를 사용하여 상태 업데이트 후 동적으로 직접 컴포넌트 렌더링 하는 경우에는 아래의 에러를 방지하도록 직접 테이블 내에 데이터를 주입
             *
             * [ERROR]
             * Objects are not valid as a React child (found: object with keys {code, desc, enumName}).
             * If you meant to render a collection of children, use an array instead.
             * **/

            /* 헤더 정보 계산 */
            const tableHeaders = [];

            if (titleElem != null && titleElem) {
                for (let i = 0; i < titleElem.length; i++) {
                    tableHeaders.push(<th title={titleElem[i]}>{titleElem[i]}</th>);
                }
            }

            /* 행 정보 계산 */
            const tableRowList = [];
            const tableKeyMap = (tableKey != null && tableKey) ? new Map(tableKey.map(key => [key, key])) : {};

            if (tableRow != null && tableRow) {
                for (let i = 0; i < tableRow.length; i++) {
                    const albumItem = tableRow[i];
                    const tdList = [];
                    Object.entries(albumItem).map(([key, value], index) => {
                        const tableKeyValue = tableKeyMap.get(key);

                        if (tableKeyValue != null) {
                            if (value != null) {
                                const numberFormatValue = (typeof value === 'number') ? this.dataUtil.currencyFormatter(value) : value;
                                tdList.push(<td title={value}>{numberFormatValue}</td>);
                            } else {
                                tdList.push(<td>{''}</td>);
                            }
                        }
                        return null;
                    });
                    tableRowList.push(<tr>{tdList}</tr>);
                }
            }

            /* 수익 상세 패이지 내 푸터 (합계) 정보 */
            const footerList = [];

            if ((footerInfo != null && footerInfo) && (tableRowList.length > 0)) {
                const tdList = [];

                for (const [key, value] of tableKeyMap.entries()) {
                    const mapValue = footerInfo[key];

                    if (mapValue == null) {
                        tdList.push(<td style={{backgroundColor: '#c6c6c6'}}></td>);
                    } else {
                        const numberFormatValue = (typeof mapValue === 'number') ? this.dataUtil.currencyFormatter(mapValue) : mapValue;
                        tdList.push(<td style={{backgroundColor: '#c6c6c6'}} title={mapValue}>{numberFormatValue}</td>);
                    }
                }

                footerList.push(
                    <tfoot className={classData.tableFooter}>
                    <tr style={{borderColor: '#ffffff', fontWeight: 'bold'}}>{tdList}</tr>
                    </tfoot>
                );
            }

            return (
                <>
                    <div className={classData.tableWrap}>
                        <table className={classData.table}>
                            <thead className={classData.tableHead}>
                            <tr className="border-bottom border-black">
                                {tableHeaders}
                            </tr>
                            </thead>
                            <tbody className={classData.tableBody}>
                            {tableRowList}
                            </tbody>
                            {footerList}
                        </table>
                    </div>

                    {
                        (pagination != null && pagination) ?
                            <CommonPagination
                                totalItems={pagination.totalItems}
                                itemCountPerPage={pagination.itemCountPerPage}
                                pageCount={pagination.pageCount}
                                currentPage={pagination.currentPage}
                                onPageChange={pagination.onPageChange}
                            /> : <></>
                    }
                </>
            );
        }
    }
}