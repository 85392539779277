import {ModelClass} from "../ModelClass";

/**
 * 공지사항 리스트 내 아이템
 */
export class NoticeDetail extends ModelClass {
    constructor(noticeItem) {
        super(noticeItem);
        this.noticeId = null; // 공지사항 ID
        this.title = null; // 제목
        this.content = null; // 본문
        this.regDate = null; // 등록일
        this.publicDate = null; // 웹명세서 공개 날짜
        this.exposeStartDate = null; // 팝업 노출 시작 알짜
        this.exposeEndDate = null; // 팝업 노출 종료 날짜
        this.prevNoticeTitle = null; // 이전 글 제목
        this.prevNoticeLink = null; // 이전 글 링크
        this.nextNoticeTitle = null; // 다음 글 제목
        this.nextNoticeLink = null; // 다음 글 링크
        // TODO
        this.init(noticeItem);
    }

    init(noticeItem) {
        Object.keys(this).forEach(keyName => {
            switch (keyName) {
                case 'regDate':
                    if (noticeItem?.[keyName]) {
                        this[keyName] = this.getDateToStr(noticeItem[keyName]);
                    }
                    break;

                default:
                    if (noticeItem?.[keyName]) {
                        this[keyName] = noticeItem[keyName];
                    }
                    break;
            }
        });
    }

    debug() {
        return `[NoticeDetail] noticeId: ${this.noticeId},  title: ${this.title}, exposeStartDate[UTC]: ${this.exposeStartDate}, exposeEndDate[UTC]: ${this.exposeEndDate}, regDate[UTC]: ${this.regDate}`;
    }
}