import React from 'react';
import {CommonTable} from '../../common/CommonTable';
import {APIConfig} from "../../../constans/APIConfig";
import AxiosClient from "../../../util/AxiosClient";
import {AlbumDigitalRevenueItem} from "../../../model/SalseRevenue/detail/AlbumDigitalRevenueItem";
import {Modal} from "../../common/Modal";
import {AlbumPhysicalRevenueItem} from "../../../model/SalseRevenue/detail/AlbumPhysicalRevenueItem";
import {PaginationUtil} from "../../../util/PaginationUtil";

export class AlbumSalesData extends React.Component {
    constructor(prop) {
        super(prop);
        this.paginationUtil = new PaginationUtil();
        this.commTable = new CommonTable();
        this.apiConfig = new APIConfig();
        this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
        this.paginationUtil = new PaginationUtil();
        this.swal = new Modal();
        this.commTable.classData.table = 'table table-bordered table-light';
        this.commTable.classData.tableHead = 'table-dark';
        this.commTable.classData.tableBody = 'table-light table-bordered';
        this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;
        this.cacheMap = new Map(); // 캐싱 데이터 맵

        // 앨범 구분 (DIGITAL,PHYSICAL)에 따른 테이블 헤더 맵
        this.tableHeaderTitlesMap = {
            'DIGITAL': [
                '발매일',
                '발매사',
                // '구분',
                '앨범코드',
                '앨범명',
                '아티스트',
                '앨범유형',
                '스트리밍 수',
                '다운로드 수',
                '기타 수',
                '저작인접권료',
                '수수료',
                '권리사 정산액',
            ],
            'PHYSICAL': [
                '발매일',
                '발매사',
                'TYPE',
                // '구분',
                '앨범코드',
                '바코드',
                '앨범명',
                '아티스트',
                // '출고가1',
                // '출고가2',
                '단가',
                '판매수량',
                '반품수량',
                '재고수량',
                '판매금액',
                '당월 예치금',
                '누적 예치금',
                '수수료',
                '권리사 정산액',
            ]
        };

        // 앨범 구분 (DIGITAL,PHYSICAL)에 따른 테이블 ROW 맵
        this.tableRowkeysMap = {
            'DIGITAL': [
                'saleStartDate',
                'distributor',
                // 'assetType',
                'assetCode',
                'assetName',
                'artist',
                'albumType',
                'cntStream',
                'cntDownload',
                'cntEtc',
                'sumRevenueMoney',
                'sumRevenueFee',
                'sumRevenueTotal',
            ],
            'PHYSICAL': [
                'saleStartDate',
                'distributor',
                'recordType',
                // 'assetType',
                'recordCode',
                'upcCode',
                'recordName',
                'artist',
                // 'exportPrice1',
                // 'exportPrice2',
                'price',
                'sumSaleCnt',
                'sumDiscardCnt',
                'inventory',
                'sumRevenueMoney',
                'monthMoneyDeposit',
                'sumMoneyDeposit',
                'sumRevenueFee',
                'sumRevenueTotal',
            ]
        };
    }

    /**
     * 총 합계 계산 API 호출 및 렌더링
     * **/
    requestTotalSum() {
        // 총합 계산 API 호출
        const assetType = document.getElementById('assetTypeOption').value;
        const apiPath = `${this.apiConfig.SALES_SERVICE_TOTAL_SUM.getPath(this.props.revenueMonth)}?searchType=ALBUM&assetType=${assetType}`;

        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                switch (assetType) {
                    case 'DIGITAL':
                        const albumDigitalRevenueList = res.data?.body["albumDigitalRevenueList"];

                        if (albumDigitalRevenueList != null && albumDigitalRevenueList) {
                            const albumDigitalRevenue = albumDigitalRevenueList[0];

                            if (albumDigitalRevenue != null && albumDigitalRevenue) {
                                const footerInfo = {
                                    'saleStartDate': '총 합계',
                                    'cntStream': albumDigitalRevenue["cntStream"],
                                    'cntDownload': albumDigitalRevenue["cntDownload"],
                                    'cntEtc': albumDigitalRevenue["cntEtc"],
                                    'sumRevenueMoney': albumDigitalRevenue["sumRevenueMoney"],
                                    'sumRevenueFee': albumDigitalRevenue["sumRevenueFee"],
                                    'sumRevenueTotal': albumDigitalRevenue["sumRevenueTotal"]
                                };

                                this.cacheMap.set(`${assetType}#footerInfo`, footerInfo);
                                this.commTable.updateFooterInfo(footerInfo);
                            }
                        }
                        break;

                    case 'PHYSICAL':
                        const albumPhysicalRevenueList = res.data?.body["albumPhysicalRevenueList"];

                        if (albumPhysicalRevenueList != null && albumPhysicalRevenueList) {
                            const albumPhysicalRevenue = albumPhysicalRevenueList[0];

                            if (albumPhysicalRevenue != null && albumPhysicalRevenue) {
                                const footerInfo = {
                                    'saleStartDate': '총 합계',
                                    'sumSaleCnt': albumPhysicalRevenue["sumSaleCnt"],
                                    'sumDiscardCnt': albumPhysicalRevenue["sumDiscardCnt"],
                                    'inventory': albumPhysicalRevenue["inventory"],
                                    'monthMoneyDeposit': albumPhysicalRevenue["monthMoneyDeposit"],
                                    'sumMoneyDeposit': albumPhysicalRevenue["sumMoneyDeposit"],
                                    'sumRevenueMoney': albumPhysicalRevenue["sumRevenueMoney"],
                                    'sumRevenueFee': albumPhysicalRevenue["sumRevenueFee"],
                                    'sumRevenueTotal': albumPhysicalRevenue["sumRevenueTotal"]
                                };
                                this.cacheMap.set(`${assetType}#footerInfo`, footerInfo);
                                this.commTable.updateFooterInfo(footerInfo);
                            }
                        }
                        break;
                }
            }
        });
    }

    /**
     * 카탈로그 구분에 따라, Table Header / Table Rows 초기화
     */
    initTableHeaderRowsByAssetType() {
        const assetTypeDOM = document.getElementById('assetTypeOption');
        if (assetTypeDOM == null || !assetTypeDOM) {
            this.tableHeadTitles = this.tableHeaderTitlesMap['DIGITAL'];
            this.commTable.tableRowkeys = this.tableRowkeysMap['DIGITAL'];
        } else {
            const assetType = document.getElementById('assetTypeOption').value;

            switch (assetType) {
                case 'DIGITAL':
                    this.tableHeadTitles = this.tableHeaderTitlesMap['DIGITAL'];
                    this.commTable.tableRowkeys = this.tableRowkeysMap['DIGITAL'];
                    break;

                case 'PHYSICAL':
                    this.tableHeadTitles = this.tableHeaderTitlesMap['PHYSICAL'];
                    this.commTable.tableRowkeys = this.tableRowkeysMap['PHYSICAL'];
                    break;
            }
        }
    }

    /**
     * 앨범 별 내역 테이블 리렌더링
     * **/
    reRenderAlbumRevenue() {
        const assetType = document.getElementById('assetTypeOption').value;
        const apiPath = `${this.apiConfig.SALES_SERVICE.getPath(this.props.revenueMonth)}${this.paginationUtil.getPaginationReq(null, document.getElementById("albumPageSizeSelect"))}&searchType=ALBUM&assetType=${assetType}`

        // 앨범 별 내역 API 호출 및 데이터 처리
        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                const responseBody = res.data?.body;
                const responseRevenueList = [];
                const footerInfo = this.cacheMap.get(`${assetType}#footerInfo`);

                switch (assetType) {
                    case 'DIGITAL':
                        if (responseBody?.['albumDigitalRevenueList'] && responseBody['albumDigitalRevenueList']?.length) {
                            responseBody['albumDigitalRevenueList'].forEach(revenue => {
                                responseRevenueList.push(new AlbumDigitalRevenueItem(revenue))
                            });
                        }
                        break;

                    case 'PHYSICAL':
                        if (responseBody?.['albumPhysicalRevenueList'] && responseBody['albumPhysicalRevenueList']?.length) {
                            responseBody['albumPhysicalRevenueList'].forEach(revenue => {
                                responseRevenueList.push(new AlbumPhysicalRevenueItem(revenue))
                            });
                        }
                        break;
                }
                this.initTableHeaderRowsByAssetType();
                this.commTable.replaceTableElement('SalesDetail', this.tableHeadTitles, this.commTable.tableRowkeys, responseRevenueList, footerInfo, this.getPagination(this, responseBody['paginationResponse']));

                if (footerInfo == null) {
                    this.requestTotalSum(); // 총합 집계
                }
            }
        });
    }

    render() {
        this.initTableHeaderRowsByAssetType();
        const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles);
        const tableRow = this.commTable.getTableRows(this.props.revenueList, 'table-bordered');
        return (<>
            <div className='row mt-4'>
                <div className='card p-3 innerCard'>
                    <div className="px-2 clear_fix">
                        <span style={{float: 'left'}}>리스트 수 : <span
                            id="revenueTotalCount">{(this.props.pagination != null && this.props.pagination) ? this.props.pagination.totalItems : 0}</span></span>

                        <div style={{float: 'right'}}>
                            <div id="pageSizeSelectWrap" className="col-md-3 ml-md-auto">
                                <div className="input-group-sm">
                                    <select id="albumPageSizeSelect" className=""
                                            style={{border: '1px solid #495057', color: '#495057'}}
                                            onChange={(e) => {
                                                this.reRenderAlbumRevenue();
                                            }}>
                                        <option value="10" selected={true}>10개 보기</option>
                                        <option value="20">20개 보기</option>
                                        <option value="30">30개 보기</option>
                                        <option value="50">50개 보기</option>
                                        <option value="100">100개 보기</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div style={{float: 'right', marginRight: '1.5rem'}}>
                            <span style={{fontSize: '1rem'}}>구분</span>
                            <select id="assetTypeOption" onChange={(e) => {
                                this.reRenderAlbumRevenue();
                            }} className="basicSelectBox">
                                <option value="DIGITAL">DIGITAL</option>
                                <option value="PHYSICAL">PHYSICAL</option>
                            </select>
                        </div>
                    </div>
                    {this.commTable.getTableElement(tableHeaders, tableRow, null, 'SalesDetail', this.getPagination(this, this.props.pagination))}
                </div>
            </div>
        </>);
    }

    /**
     * 페이지네이션 이벤트 메서드
     */
    getPagination(self, paginationObj) {
        // 리스트 수 UPDATE
        if (document.getElementById("revenueTotalCount") != null && document.getElementById("revenueTotalCount")) {
            document.getElementById("revenueTotalCount").innerText = (paginationObj != null && paginationObj) ? paginationObj["totalItems"] : 0;
        }

        return {
            totalItems: (paginationObj != null && paginationObj) ? paginationObj["totalItems"] : null,
            itemCountPerPage: (paginationObj != null && paginationObj) ? paginationObj["itemCountPerPage"] : null,
            pageCount: (paginationObj != null && paginationObj) ? paginationObj["pageCount"] : null,
            currentPage: (paginationObj != null && paginationObj) ? paginationObj["currentPage"] : null,
            onPageChange: (pageNumber) => {
                // 서버 사이드 렌더링
                const assetType = document.getElementById('assetTypeOption').value;
                const apiPath = `${this.apiConfig.SALES_SERVICE.getPath(this.props.revenueMonth)}${this.paginationUtil.getPaginationReq(pageNumber, document.getElementById("albumPageSizeSelect"))}&searchType=ALBUM&assetType=${assetType}`

                // 앨범 별 내역 API 호출 및 데이터 처리
                this.apiCaller.getRequest(apiPath, (res) => {
                    // 세션 만료 (401) 발생 시에 로그인 요청
                    if(res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                        this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                        return;
                    }

                    if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                        this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                        return;
                    }

                    // 200 응답일 때에만 테이블 렌더링
                    if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                        const responseBody = res.data?.body;
                        const responseRevenueList = [];
                        const footerInfo = this.cacheMap.get(`${assetType}#footerInfo`);

                        switch (assetType) {
                            case 'DIGITAL':
                                if (responseBody?.['albumDigitalRevenueList'] && responseBody['albumDigitalRevenueList']?.length) {
                                    responseBody['albumDigitalRevenueList'].forEach(revenue => {
                                        responseRevenueList.push(new AlbumDigitalRevenueItem(revenue))
                                    });
                                }
                                break;

                            case 'PHYSICAL':
                                if (responseBody?.['albumPhysicalRevenueList'] && responseBody['albumPhysicalRevenueList']?.length) {
                                    responseBody['albumPhysicalRevenueList'].forEach(revenue => {
                                        responseRevenueList.push(new AlbumPhysicalRevenueItem(revenue))
                                    });
                                }
                                break;
                        }
                        this.initTableHeaderRowsByAssetType();
                        this.commTable.replaceTableElement('SalesDetail', this.tableHeadTitles, this.commTable.tableRowkeys, responseRevenueList, footerInfo, this.getPagination(this, responseBody['paginationResponse']));

                        if (footerInfo == null) {
                            this.requestTotalSum(); // 총합 집계
                        }
                    }
                });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidMount() {
        // 해당 페이지 Render 된 후에 전체 합계를 구하는 메서드 호출
        this.requestTotalSum(); // 총합 집계
    }

    componentDidUpdate(prevProps, prevState) {
        // 해당 페이지를 벗어난 후에 호출 되는 콜백 메서드
        this.requestTotalSum(); // 총합 집계
    }
}