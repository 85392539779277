export class APIConfig{
  constructor(){
    this.API_URL = process.env.REACT_APP_API_URL

    this.LOGIN = {//로그인
      method : 'POST',
      path : 'comm/loginProxy',
      body : null
    }

    this.LOGOUT = {//로그아웃
      method : 'POST',
      path : 'logout',
      body : null
    }

    this.CHANGE_PASSWORD = {//비밀번호 변경
      method : 'POST',
      path : 'rightsHolders/changePassword',
      body : null
    }

    this.RIGHTSHOLDERS = {//권리자 상세조회
      method : 'GET',
      path : 'rightsHolders',
      body : null,
    }

    this.SALES_TRANSACTION_PROOF = {//정산 명세서 > 거래증빙 관련 정보
      method : 'GET',
      path : 'sales',
      getPath : (revenueMonth)=>{
        return `sales/${revenueMonth}`;
      },
      body : null
    }

    this.SALES_SERVICE = {//정산 명세서 > [판매처/앨범/트랙] 별 수익 상세
      method : 'GET',
      path : 'sales',
      getPath : (revenueMonth)=>{
        return `sales/${revenueMonth}/service`
      },
      body : null
    }

    this.SALES_SERVICE_TOTAL_SUM = {//정산 명세서 > [판매처/앨범/트랙] 별 수익 상세 총합
      method : 'GET',
      path : 'sales',
      getPath : (revenueMonth)=>{
        return `sales/${revenueMonth}/service/totalSum`
      },
      body : null
    }

    this.SALES_REVENUE = {//정산 명세서 > 수익 관련 정보
      method : 'GET',
      path : 'sales',
      getPath : (revenueMonth)=>{
        return `sales/${revenueMonth}/revenue`
      },
      body : null
    }

    this.SALES_CONTRACT_PREPAID = {//정산 명세서 > 선급금 상계 내역 > 선급 계약 리스트
      method : 'GET',
      path : 'contract/prepaid',
      getPath : (contractId, revenueMonth)=>{
        return `contract/prepaid/${contractId}/${revenueMonth}`;
      },
      body : null
    }

    this.NOTICE_LIST = { // 공지사항 목록
      method: 'GET',
      path: 'notice/list',
      getPath: (rightId, queryStr) => {
        return `notice/list${queryStr}&rightId=${rightId}`;
      }
    }

    this.NOTICE_VIEW = { // 공지사항 상세
      method: 'GET',
      path: 'notice/view',
      getPath: (noticeId, rightId) => {
        return `notice/view/${noticeId}?rightId=${rightId}`;
      }
    }

    this.NOTICE_POPUP = { // 공지사항 팝업
      method: 'GET',
      path: 'notice/popup',
      getPath: (rightId) => {
        return `notice/popup?rightId=${rightId}`;
      }
    }

    this.DOWNLOAD = { // 다운로드
      method: 'GET',
      path: 'download/specRightHolder'
    }

    this.ERROR_TYPE = {
      HTTP : 0,
      API : 1,
      STATUS : 2,
      RUNTIME : 3
    }

    this.ERROR_CODE = {
      LOGIN : {
        FAIL : 1000,
      }
    }

    this.HTTP_STATUS_CODE = {
      //Information responses 1xx
      INFO_100_CONTINUE : 100,
      INFO_101_SWITCHING_PROTOCOL : 101,
      INFO_102_PROCESSING : 102,
      INFO_103_EARLY_HINTS : 103,

      //Successful responses 2xx
      SUCCESS_200_OK : 200,
      SUCCSSS_201_CREATED : 201,
      SUCCESS_202_ACCEPTED : 202,
      SUCCESS_203_NON_AUTHORITATIVE : 203,
      SUCCESS_204_NO_CONTENT : 204,
      SUCCESS_205_REST_CONTENT : 205,
      SUCCESS_206_PARTIAL_CONTENT : 206,
      SUCCESS_207_MULTI_STATUS : 207,
      SUCCESS_208_MULTI_STATUS : 208,
      SUCCESS_226_IM_USED : 226,

      //Redirection messages 3xx
      REDIRECT_300_MULTIPLE_CHOICE : 300,
      REDIRECT_301_MOVED_PERMANENTLY : 301, 
      REDIRECT_302_FOUND : 302,
      REDIRECT_303_SEE_ORTHER : 303,
      REDIRECT_304_NOT_MODIFIED : 304,
      REDIRECT_307_TEMP_REDIRECT : 307,
      REDIRECT_PERMANNET_REDIRECT : 308,
      
      //Client error responses 4xx
      CLIENT_400_BAD_REQUEST : 400,
      CLIENT_401_UNAUTHORIZED : 401,
      CLIENT_403_FORBIDDEN : 403,
      CLIENT_404_NOT_FOUND : 404,
      CLIENT_405_METHOD_NOTALLOWED : 405,
      CLIENT_406_NOT_ACCEPTABLE :406,
      CLIENT_407_PROXY_AUTHREQUIRED : 407,
      CLIENT_408_TIMEOUT : 408,
      CLIENT_408_CONFLICT : 409,
      CLIENT_410_GONE : 410,
      CLIENT_411_LENGTH_REQUIRED : 411,
      CLIENT_412_PRECONDITION_FAILED : 412,
      CLIENT_413_PAYLOAD_LARGE : 413,
      CLIENT_414_URI_LONG : 414,
      CLIENT_415_UNSUPPORTED_MEIDA : 415,

      //Server error responses 5xx
      SERVER_500_INTERNAL_ERROR : 500,
      SERVER_501_NOT_IMPLEMENTED : 501,
      SERVER_502_BAD_GATEWAY : 502,
      SERVER_503_SERVICE_UNAVAILABLE : 503,
      SERVER_504_GATEWAY_TIMEOUT : 504,
      SERVER_505_HTTP_VERSION : 505,
      SERVER_506_VARIANT_NEGOTIATES : 506,
      SERVER_507_INSUFFICIENT_STORATE : 507,
      SERVER_508_LOOP_DETECTED : 508,
      SERVER_510_NOT_EXTENDED : 510,
      SERVER_511_NETWORK_AUTHREQUIRED : 511
    }
  }
}