import {ModelClass} from "../../ModelClass";

/**
 * 앨범(PHYSICAL) 별 내역
 */
export class AlbumPhysicalRevenueItem extends ModelClass {
    constructor(revenueItem) {
        super(revenueItem);
        this.revenueMonth = 0; //분배월
        this.calculateMonth = 0;//정산월
        this.saleStartDate = null;//발매일
        this.distributor = null;// 발매사
        this.recordType = null; // 음반 유형
        this.assetType = null; // 카탈로그 구분
        this.recordCode = null; // 음반 코드
        this.upcCode = null; // UPC 코드
        this.recordName = null;// 음반명
        this.artist = null; // 아티스트
        this.exportPrice1 = null; // 출고가1
        this.exportPrice2 = null; // 출고가2
        this.price = null; // 단가
        this.sumSaleCnt = null; // 판매 수량
        this.sumDiscardCnt = null; // 반품 수량
        this.inventory = null; // 재고
        this.sumRevenueMoney = 0; // 판매액 (매출)
        this.monthMoneyDeposit = null; // 당월 예치금
        this.sumMoneyDeposit = null; // 누적 예치금
        this.sumRevenueFee = 0; // 수수료
        this.sumRevenueTotal = 0; // 수익 (수수료 제외)
        this.init(revenueItem);
    }

    init(revenueItem) {
        Object.keys(this).forEach(keyName => {
            switch (keyName) {
                case 'revenueMonth':
                case 'calculateMonth':
                case 'serviceMonth':
                    if (revenueItem?.[keyName]) {
                        this[keyName] = this.getYearMonthStr(revenueItem[keyName], this.fmt_yyyyMM_dot);
                    }
                    break;

                case 'saleStartDate':
                    this[keyName] = this.getDateToStr(revenueItem[keyName]);
                    break;

                case 'sumSaleCnt':
                case 'sumDiscardCnt':
                case 'inventory':
                case 'exportPrice1':
                case 'exportPrice2':
                case 'price':
                case 'monthMoneyDeposit':
                case 'sumMoneyDeposit':
                case 'sumRevenueMoney':
                case 'sumRevenueFee':
                case 'sumRevenueTotal':
                    this[keyName] = this.getNumberWithDecimaPoint(revenueItem?.[keyName], 2);
                    break;

                case 'recordType':
                    if (revenueItem?.[keyName]) {
                        this[keyName] = revenueItem[keyName]['desc'];
                    }
                    break;

                default:
                    if (revenueItem?.[keyName]) {
                        this[keyName] = revenueItem[keyName];
                    }
                    break;
            }
        });
    }
}