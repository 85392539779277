import { CommonShare } from "../../../common/CommonShare";

export class SubtractionShare extends CommonShare{
  constructor(prop){
    super(prop);
    this.shareTitle = '수익 분배 차감';
    this.tableColsTitles = [
      '대상 권리자',
      '앨범 명',
      '트랙 명',
      '스트리밍 수',
      '다운로드 수',
      '기타/판매 수',
      '분배 %',
      '분배금액'
    ];

    this.commTable.tableRowkeys =[
      'rightName',
      'assetNameKo',
      'itemNameKo',
      'cntStream',
      'cntDownload',
      'cntEtc',
      'revenueRate',
      'sumRevenueMoney'
    ];
  }

  render(){
    if(this.props.takeCalculateRevenue?.length > 0){
      let totalSum = 0;
      totalSum = this.props.takeCalculateRevenue.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.sumRevenueMoney;
      }, 0); 
      this.state ={
        shareList : this.props.takeCalculateRevenue,
        sum : totalSum
      }
      return(<>
        {this.getEleTop()}
        {this.getElemTable()}
      </>);
    }else{
      return(<></>);
    }
  }
}