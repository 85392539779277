import { CommonTotalSum } from "../../../common/CommonTotalSum";

export class SubtractionTotalSum extends CommonTotalSum{
  constructor(prop){
    super(prop);
    this.totalSumTitle = '차감 합계';
    this.sumFontClassName = 'text-danger';
  }

  render(){
    return(this.getTotalSumElem());
  }
}