export class ModelClass {
    constructor(data) {
        this.fmt_yyyyMM_dash = 0;
        this.fmt_yyyyMM_dot = 1;
        this.fmt_yyyyMMdd_dash = 2;
        this.fmt_yyyyMMdd_dot = 3;
    }

    getNumberWithDecimaPoint(amountValue, pointLenth) {
        try {
            const pointLimit = Math.pow(10, pointLenth);

            if (amountValue != null && amountValue && amountValue > 0) {
                return Math.floor(amountValue * pointLimit) / pointLimit;
            } else {
                if(amountValue == null) {
                    return 0;
                } else if (amountValue < 0) {
                    // (-) 금액의 경우에도 표시 해주도록 한다.
                    return (-1) * Math.floor(Math.abs(amountValue) * pointLimit) / pointLimit;
                } else {
                    return amountValue;
                }
            }
        } catch (exception) {
            console.error(exception);
            return 0;
        }
    }

    getStrDateToIntlDate(strDate) {
        const dateObject = new Date(strDate);
        let intlDate = 0;

        intlDate += dateObject.getFullYear() * 10000;
        intlDate += (dateObject.getMonth() + 1) * 100;
        intlDate += dateObject.getDate();

        return intlDate;
    }

    getIntNum(targetObject, keyName) {
        return (targetObject && targetObject[keyName]) ? parseInt(targetObject[keyName]) : 0;
    }

    /**
     *
     * @param {Number} numbericData
     * 숫자를 세자리씩 ','으로 구분하여 String으로 반환
     * @returns String
     */
    getCurrencyValue(numbericData) {
        return new Intl.NumberFormat().format(numbericData);
    }

    getRateValue(rateData) {
        return `${Math.floor(rateData * 100) / 100}`
    }

    getDateToStr(dateObject) {
        if (dateObject) {
            const userDate = (dateObject && typeof dateObject === 'object') ? dateObject : new Date(dateObject);
            const strYYYY = userDate.getFullYear();
            const strMM = ((userDate.getMonth() + 1) < 10) ? '0' + (userDate.getMonth() + 1) : userDate.getMonth() + 1;
            const strDD = (userDate.getDate() < 10) ? '0' + userDate.getDate() : userDate.getDate();
            return `${strYYYY}-${strMM}-${strDD}`;
        } else {
            return null;
        }
    }

    getYearMonthStr(yearMonthData, formatType) {
        const dateLength = `${yearMonthData}`.length;
        const divideYear = (dateLength === 6) ? 100 : 10000;
        const year = Math.floor(yearMonthData / divideYear);
        const month = Math.floor((yearMonthData % divideYear) / (divideYear / 100));
        const day = yearMonthData % 100;
        let dateObject = new Date(year, month - 1, day);

        const strYYYY = `${dateObject.getFullYear()}`;
        const strMM = ((dateObject.getMonth() + 1) < 10) ? `0${dateObject.getMonth() + 1}` : `${dateObject.getMonth() + 1}`;
        const strDD = (dateObject.getDate() < 10) ? `0${dateObject.getDate()}` : `${dateObject.getDate()}`;

        let yearMonthDayArray = [strYYYY, strMM];

        if (formatType === this.fmt_yyyyMMdd_dash || formatType === this.fmt_yyyyMMdd_dot) {
            yearMonthDayArray.push(strDD);
        }

        let joinSeparator = '-';
        if (formatType === this.fmt_yyyyMM_dot || formatType === this.fmt_yyyyMMdd_dot) {
            joinSeparator = '.';
        }

        return yearMonthDayArray.join(joinSeparator);
    }
}