import { ModelClass } from "./ModelClass";

export class CarryOver extends ModelClass{
  constructor(carryOver){
    super(carryOver)
    this.revenueMonth = null;
    this.forwardedAmount = null;
    this.carriedOverAmount = null;
    this.carryOverMemo = null;

    this.init(carryOver);
  }

  init(carryOver){
    const keyList = Object.keys(this);

    keyList.forEach(keyName=>{
      switch(keyName){
        case 'revenueMonth':
          this[keyName] = carryOver[keyName];
        break;

        case 'forwardedAmount':
        case 'carriedOverAmount':
          this[keyName] = this.getCurrencyValue(carryOver[keyName]);
        break;

        default:
          this[keyName] = carryOver[keyName];
        break;
      }
    });
  }
}