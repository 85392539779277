import React from 'react';
import { ModifyHistory } from './ModifiedInfo/ModifyHistory';
import { ModifyTotalSum } from './ModifiedInfo/ModifyTotalSum';
import { TaxList } from './TaxInfo/TaxList';
import { TaxTotalSum } from './TaxInfo/TaxTotalSum';
import { DataUtil } from '../../../util/DataUtil';
export class TaxInfo extends React.Component{
  constructor(prop){
    super(prop);
    this.dataUtil = new DataUtil();
  }

  getElemTop(){
    return(<>
      <div className='row'>
        <div className='d-flex align-items-start'>
          <div className='fw-bolder drag-disable'>&nbsp;세금&nbsp;</div>
          <div className='ms-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
            </svg>
          </div>
        </div>
      </div>
    </>)
  }

  getNumberWithDecimaPoint(amountValue , pointLenth){
    if(amountValue && amountValue > 0){
      const pointLimit = Math.pow(10,pointLenth);
      return Math.floor(amountValue * pointLimit)/pointLimit;
    }else{
      return 0;
    }
  }

  render(){
    const salesProof = this.props.salesProof;
    const taxSum = (salesProof?.vat? salesProof?.vat : 0) + (salesProof?.residentTax? salesProof?.residentTax : 0)
    if(taxSum > 0){

      const strPaymentAmount = this.dataUtil.currencyFormatter(salesProof?.paymentAmount);
      const strResidentTax = this.dataUtil.currencyFormatter(salesProof?.residentTax);
      const strVat = this.dataUtil.currencyFormatter(salesProof?.vat);
      const taxRate = this.dataUtil.currencyFormatter(salesProof?.taxRate);

      this.state = {
        paymentAmount :strPaymentAmount,
        residentTax : `${strResidentTax}` ,
        vat : `${strVat}`,
        taxRate: `${taxRate}%`
      }
    }


    return(<>
      <div className='row mt-4'>
        <div className='card p-3 innerCard'>
          {this.getElemTop()}
          {
            taxSum > 0 ? <TaxList taxInfo={this.state} /> :<></>
          }
          <TaxTotalSum  
            totalSum = {taxSum} 
            royalty = {this.props?.salesProof?.royaltyType}
          />
        </div>
      </div>
    </>)
  }
}