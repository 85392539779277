import React from 'react';
import { RevenueAggregation } from './Revenue/RevenueAggregation';
import { RevenueCarryOver } from './Revenue/RevenueCarryOver';
import { RevenueShare } from './Revenue/RevenueShare';
import { ReveneneTotalSum } from './Revenue/RevenueTotalSum';

export class RevenueInfo extends React.Component{
  constructor(props){
    super(props);
 }


 getTotalSum() {
     const calculateTotalSale = this.props.calculateTotalSale;
     const getCalculateRevenue = this.props.getCalculateRevenue;
     const carryOver = this.props.carryOver;
     let totalSum = 0;

     // 수익 집계 총합
     if (calculateTotalSale && calculateTotalSale?.length) {
         totalSum += calculateTotalSale.reduce((accumulator, currentItem) => {
             return accumulator + currentItem.totalAmountPaid;
         }, 0);
     }

     // 수익 분배 총합
     if (getCalculateRevenue && getCalculateRevenue?.length) {
         totalSum += getCalculateRevenue.reduce((accumulator, currentItem) => {
             return accumulator + currentItem.sumRevenueMoney;
         }, 0);
     }

     // 전기 이월 총합
     if (carryOver && carryOver?.length) {
         const forwardedAmount = carryOver[carryOver.length - 1].forwardedAmount;
         totalSum += (forwardedAmount != null && forwardedAmount) ? forwardedAmount : 0.0;
     }
     return totalSum;
 }

  render() {
      return (
          <div className='row mt-4'>
              <div className='card p-3 innerCard '>
                  {(this.props.calculateTotalSale?.length) ? <RevenueAggregation calculateTotalSale={this.props.calculateTotalSale}/> : <> </>}
                  {(this.props.carryOver?.length) ? <RevenueCarryOver carryOver={this.props.carryOver}/> : <></>}
                  <RevenueShare getCalculateRevenue={this.props.getCalculateRevenue}/>
                  <ReveneneTotalSum totalSum={this.getTotalSum()}/>
              </div>
          </div>
      );
  }
}