import { ModelClass } from "./ModelClass";
import { Royaty } from "./Royalty";

export class RightsHolders extends ModelClass{
  constructor(rightsHolders){
    super(rightsHolders);
    this.rightId = null;//권리자ID
    this.royalty = null;//정산유형 + 정산정보
    this.loginId = null;//로그인ID
    this.name = null;//권리자 명
    this.type = null;//권리자 형태
    this.email = null;//권리자 이메일
    this.taxBillingEmail = null; // 세금 계산서 발행 이메일 주소
    this.brandCode = null;//브랜드 정보
    this.changePasswordLastDate = null;//최근 비밀번호 변경 날짜
    this.init(rightsHolders);
  }

  init(rightsHolders){
    Object.keys(this).forEach(keyName=>{
      // eslint-disable-next-line default-case
      switch(keyName){
        case 'rightId':
          this[keyName] = this.getIntNum(rightsHolders, keyName);
        break;

        case 'royalty':
          if(rightsHolders[keyName]){
            this[keyName] = new Royaty(rightsHolders[keyName]);
          }
        break;

        default:
          if(rightsHolders[keyName]){
            this[keyName] = rightsHolders[keyName];
          }
        break;
      }
    });
  }
}