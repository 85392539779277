import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../media/icon/month-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../media/icon/month-right.svg';
import '../../../css/common.css';
import '../../../css/componet/statements/settlmentInfo/DateInfo.css';
import { APIConfig } from '../../../constans/APIConfig';
import AxiosClient from '../../../util/AxiosClient';
import { SalesProof } from '../../../model/SalesProof';
import {DataUtil} from "../../../util/DataUtil";
export class DateInfo extends React.Component{ 
  constructor(props){
    super(props);
    this.state  = {
      revenueMonth : this.props.revenueMonth
    }
    this.apiConfig = new APIConfig();
    this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
    this.dataUtil = new DataUtil();
    this.SALES_TRANSACTION_PROOF = this.apiConfig.SALES_TRANSACTION_PROOF;
    this.SALES_SERVICE = this.apiConfig.SALES_SERVICE;
    this.SALES_REVENUE = this.apiConfig.SALES_REVENUE;
    this.SALES_CONTRACT_PREPAID = this.apiConfig.SALES_CONTRACT_PREPAID;
    this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;
  }

  componentWillMount(){

  }

  componentDidMount() {
    if (!this.props.revenueMonth) {
      // 예전미디어외에는 분배월이 -1 이라 모두 이전달로 변경
      const rightHolder = this.props.rightHolder;
      const tmp = new Date();
      const year = tmp.getFullYear();

      /**
       * 예전미디어의 경우는 이전달로 변경하지 않는다.
       * 그 외에 권리자는 이전달(현재달의 -1)로 보여지도록 한다.
       */
      if (!rightHolder || this.dataUtil.checkVostokYejeonMedia(rightHolder.brandCode.code, rightHolder.rightId) === false) {
        if (tmp.getMonth() > 0)
          tmp.setMonth(tmp.getMonth() - 1);
        else {
          tmp.setFullYear(year - 1);
          tmp.setMonth(11);
        }
        /**
         * 보정된 월이 의도한 월과 다른 경우, 날짜를 0으로 설정하여 이전 달의 마지막 날로 조정
         * Ex) 2024-07-31의 경우, 2024-06-31은 없는 날짜이므로, 이전달로 해도 7월이 보여지는데 이러한 경우 이전달의 마지막 날짜로 보정
         * **/
        if (tmp.getMonth() === (new Date().getMonth())) {
          tmp.setDate(0);
        }
      }
      this.updateYearMonth(tmp);
    }
  }

  changeYearMonth=(evt)=>{
    if(evt.target.value){
      const yyyymm = new Date(evt.target.value);
      this.updateYearMonth(yyyymm);
    }
  }

  updateYearMonth =(dateInfo)=>{
    if(dateInfo){
      const monthInput = document.querySelector('input.month-input[type=month]');
      const fullYearString = dateInfo.getFullYear();
      let monthString = dateInfo.getMonth()+1;
      monthString = (monthString < 10)? '0'+monthString : monthString;
      monthInput.value = `${fullYearString}-${monthString}`;
      const intRevenueMonth = (fullYearString * 100 )+ (dateInfo.getMonth()+1);
      this.getSalesProof(intRevenueMonth);
    }
  }

  getSalesProof =(intRevenueMonth)=>{
    try{
      this.apiCaller.getRequest(this.SALES_TRANSACTION_PROOF.getPath(intRevenueMonth), (response)=>{
        let proofData = null;
        if(response.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && response?.data && response.data.body){
          proofData = new SalesProof(response.data.body);
        }else{
          proofData = new SalesProof(null);
        }
        this.props.onStateChange(intRevenueMonth, proofData);
        this.setState({revenueMonth : intRevenueMonth});
      })
    }catch(excp){
      console.error(excp);
    }
  }

  clickToArrowButton =(actionName, clickEvent)=>{
    let targetYear = Math.floor(this.state.revenueMonth/100);
    let targetMonth = this.state.revenueMonth - (targetYear *100);
    if(actionName === 'pre'){
      targetMonth -= 1;
      if(targetMonth < 1){
        targetYear -= 1;
        targetMonth = 12;
      }
      
    }else if(actionName === 'next'){
      targetMonth += 1;
      if(targetMonth > 12){
        targetYear += 1;
        targetMonth = 1;
      }
    }
    this.updateYearMonth(new Date(`${targetYear}-${(targetMonth < 10) ? `0${targetMonth}`:targetMonth}`));
  }

  getYearMonth(valueType){
    const revenueYear = Math.floor(this.state.revenueMonth/100);
    const revenueMonth = this.state.revenueMonth - (revenueYear *100);
    if(!valueType){
      return `${revenueYear}-${(revenueMonth < 10)? '0'+revenueMonth : revenueMonth}`;
    }else{
      if(valueType === 'year'){
        return revenueYear;
      }
      if(valueType === 'month'){
        return (revenueMonth < 10)? '0'+revenueMonth : revenueMonth;
      }
    }
  }

  render(){
    return(
      <div>
        <div className='row mt-2'>
          <span className='col-sm-3'>&nbsp;</span>
          <span className='col-sm-6 px-3'>
            <div className='border border-dark rounded-pill text-center drag-disable'>정산 월</div>
          </span>
          <span className='col-sm-3'>&nbsp;</span>
        </div>
        <div className='row mt-3'>
          <span className='col-sm-3'>
            <span className='icon date-info-icon border-radius-md month-arrow-icon float-end ms-cursor-over' onClick={(e)=>this.clickToArrowButton('pre', e)}>
              <ArrowLeftIcon date-action='pre'/>
            </span>
          </span>
          <span className='col-sm-6 row'>
            <span className='col icon date-info-icon border-radius-md date-ragne-icon'>
              <input className='month-input ms-cursor-over'type='month' defaultValue={this.getYearMonth} onChange={this.changeYearMonth}></input>
            </span>
            <span className='col text-center'>
              <span className='drag-disable'>
                <span className='date-info-year'>
                  {this.getYearMonth('year').toString().substring(0,4)}.
                </span>
                <span className='date-info-month'>
                {this.getYearMonth('month')}
                </span>
              </span>
            </span>
          </span>
          <span className='col-sm-3'>
            <span className='icon date-info-icon border-radius-md month-arrow-icon ms-cursor-over'onClick={(e)=>this.clickToArrowButton('next' , e)}>
              <ArrowRightIcon date-action='next'/>
            </span>
          </span>
        </div>
      </div>
    );
  }
}