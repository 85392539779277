import Swal from "sweetalert2";

export class Modal{
  constructor(){
    this.defaultModalOption = {
      allowEscapeKey : false,
      allowOutsideClick : false,
      allowEnterKey : true,
      keydownListenerCapture : false,
    }
  }


  alert(alertMessage, confirmCallBack, iconTitle = 'info'){    
    const modalSetting = {...this.defaultModalOption};
    modalSetting.icon = iconTitle;
    modalSetting.html = alertMessage;
    modalSetting.confirmButtonText = '확인';

    Swal.fire(modalSetting).then((result)=>{
      if(result.isConfirmed){
        if(confirmCallBack){
          confirmCallBack();
        }
      }
    });
  }

  /** 이월/선급 내역 등등 모달 팝업 **/
  settleHistoryModal(modalTitle, htmlDom , modalWidth = '50em' , didOpenCallBack){
    let styleString = `font-size: 1.3rem;`;
    styleString += `padding-bottom: 10px;`;
    styleString += `border-bottom: 2px solid #a9a9a9;`;
    styleString += `text-align: left;`;
    const modalSetting = {...this.defaultModalOption};
    modalSetting.confirmButtonText = '닫기';
    modalSetting.title = `<div style="${styleString}">${modalTitle}</div>`;
    modalSetting.width = modalWidth;
    modalSetting.html = htmlDom;
    if(didOpenCallBack){
      modalSetting.didOpen = didOpenCallBack;
    }
    Swal.fire(modalSetting);
  }

  /** 비밀번호 변경 모달 팝업 **/
  changePasswordModal(modalTitle, htmlDom, showCancelButton, successCallBack, modalWidth = '50em'){
    let styleString = `font-size: 1.3rem;`;
    styleString += `padding-bottom: 10px;`;
    styleString += `border-bottom: 2px solid #a9a9a9;`;
    styleString += `text-align: left;`;

	const pwdRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*()\-_])[A-Za-z\d~!@#$%^&*()\-_]{8,}$/;
	
    Swal.fire({
		title : `<div style="${styleString}">${modalTitle}</div>`,
		allowOutsideClick : false,	// 모달 외부를 클릭하여 모달을 닫을 수 있는지 여부
		//allowEnterKey : false,		// Enter Key 동작 여부
		allowEscapeKey : false,		// ESC 키를 모달을 닫을 수 있는지 여부
		showCancelButton : showCancelButton,
		confirmButtonText : '저장',
		cancelButtonText : '취소',
		html : htmlDom,
		width : modalWidth,
		preConfirm : ()=>{
			const passwordWrap = document.querySelector('div#changePasswordModal');
		    const pwd = passwordWrap.querySelector('input#pwd[type=password]');
		    const chgPwd = passwordWrap.querySelector('input#chgPwd[type=password]');
		    const chgPwdConfirm = passwordWrap.querySelector('input#chgPwdConfirm[type=password]');
		    
		    const pwdValue = pwd.value.trim();
		    const chgPwdValue = chgPwd.value.trim();
		    const chgPwdConfirmValue = chgPwdConfirm.value.trim();
		    
		    if(pwdValue === "") {
				alert("'기존 비밀번호'를 입력해주세요.");
				return false;
			}else if(chgPwdValue === "") {
				alert("'변경 비밀번호'를 입력해주세요.");
				return false;
			}else if(chgPwdConfirmValue === "") {
				alert("'변경 비밀번호 확인'을 입력해주세요.");
				return false;
			}else if(pwdValue === chgPwdValue) {
				alert("동일한 비밀번호로 변경할 수 없습니다.");
				return false;
			}else if(!pwdRegex.test(chgPwdValue)) {
				alert('비밀번호 형식이 올바르지 않습니다.');
				return false;
			}else if(chgPwdValue !== chgPwdConfirmValue) {
				alert('변경 비밀번호가 일치하지 않습니다.');
				return false;
			}
		    
			if(successCallBack){
				successCallBack(pwdValue, chgPwdValue, chgPwdConfirmValue);
			}
		}
		
	});
  }

  confirm(alertMessage, confirmCallBack, button = {confirm : '확인', cancel : '취소' }){
    const modalSetting = {...this.defaultModalOption};
    modalSetting.icon = 'question';
    modalSetting.text = alertMessage;
    modalSetting.showCancelButton = true;
    modalSetting.cancelButtonText = button.cancel;
    modalSetting.confirmButtonText = button.confirm;
    Swal.fire(modalSetting).then((result)=>{
      if(result.isConfirmed){
        if(confirmCallBack){
          confirmCallBack();
        }
      }
    });
  }
}