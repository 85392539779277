import React from 'react';
import '../../css/common.css'
import '../../css/layout/LayoutSidebar.css'
import { ReactComponent as IconHome } from '../../media/icon/home.svg';
import { ReactComponent as IconMenu } from '../../media/icon/menu.svg';
import { ReactComponent as IconPersonGear } from '../../media/icon/person-gear.svg';
import { ReactComponent as IconLogOut } from '../../media/icon/box-arrow-right.svg';
import { Modal } from '../common/Modal';
import { APIConfig } from '../../constans/APIConfig';
import { Password } from "../../model/auth/Password";
import AxiosClient from '../../util/AxiosClient';

export class LayoutNavbar extends React.Component{
  constructor(prop){
    super(prop);
    this.apiConfig = null;
    this.apiCaller = null;
    this.rightsHolders = this.props.rightsHolders;
    this.fromCall = 'layoutNav';
    this.sideBarMenuType = this.props.sideBarMenuType;
    this.swal = null;
  }

  // 사이드바 메뉴 변경을 상위 컴포넌트로 전달
  switchToSideBarMenuType = (type) => {
    if (type != null && type) {
      this.props.onMenuChange(type);
    }
  };

  componentDidMount(){

  }

  getIconElement(iconTitle,ReactComponent){
    return(
      <div>
        <span className='icon header-icon border-radius-md'>
          <ReactComponent />
        </span>
        <span className='p-2 drag-disable'>{iconTitle}</span>
      </div>
    );
  }
  
  getPasswordForm(){
    return `<div id="changePasswordModal">
    	<div class="text-start" >
    	<label>대문자,소문자,숫자,특수문자 각 하나 이상 포함하여 8자리 이상<label style="color:red;">특수문자 ~ ! @ # $ % ^ & * ( ) - _</label></label>
    	</div>
		<div class="text-start" >
		<label>기존 비밀번호</label>
  		<input type="password" class="form-control" id="pwd" autoComplete="off">
		</div>
		<div class="text-start">
		<label>변경 비밀번호</label>
  		<input type="password" class="form-control" id="chgPwd" autoComplete="off">
		</div>
		<div class="text-start">
		<label>변경 비밀번호 확인</label>
  		<input type="password" class="form-control" id="chgPwdConfirm" autoComplete="off">
		</div></div>
	`;
  }
  
  changePassword=(fromCall)=>{
	this.fromCall = fromCall;
	const showCancelButton = (this.fromCall === "layoutNav") ? true : false;
	
	if(!this.swal){
      this.swal = new Modal();
    }
    
    
    this.swal.changePasswordModal('비빌번호 변경', this.getPasswordForm(), showCancelButton, this.reqChangePassword, '10cm');
    	
    return;
  }
  
  reqChangePassword=(password, changePassword, changePasswordConfirm)=>{
    if(!this.apiConfig){
      this.apiConfig = new APIConfig();
    }

    if(!this.apiCaller){
      this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
    }
    
    const reqChangePassword = this.apiConfig.CHANGE_PASSWORD;
    const passwordInfo = new Password(password,changePassword,changePasswordConfirm);
    this.apiCaller.postRequest(`${reqChangePassword.path}`,  passwordInfo, this.changePasswordSuccess);
  }
  
  changePasswordSuccess=(response)=>{
    const responseData = response?.data;
    const responseResultCode = responseData?.resultCode;
    //const responseBody = responseData?.body;
    
    if(!this.swal){
      this.swal = new Modal();
    }
    
    if(responseResultCode){
	  if(this.fromCall === "index")
      	this.swal.alert(`${responseResultCode.desc}<br>[Code: ${responseResultCode.code}]`, ()=>{
			  window.location.reload();
		  });
      else
        this.swal.alert(`${responseResultCode.desc}<br>[Code: ${responseResultCode.code}]`);
    }
  }
  
  logout =(e)=>{
    const modalUi = new Modal();
    modalUi.confirm('로그아웃 하시겠습니까?',this.reqLogout, {confirm : '로그아웃', cancel : '취소'});
    e.stopPropagation();
    return;
  }

  reqLogout=()=>{
    if(!this.apiConfig){
      this.apiConfig = new APIConfig();
    }
    if(!this.apiCaller){
      this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
    }
    const reqLogout = this.apiConfig.LOGOUT;
    this.apiCaller.postRequest(reqLogout.path, null, ()=>{
      window.location.reload();
    });
  }

  reqHome = () => {
    window.location.reload();
  }

  reqSideMenu = () => {
    const sideMenu = document.getElementById('sideMenu');
    const containerFluid = document.getElementById('containerFluid')

    // sideMenu init
    sideMenu.style.transition = '0.5s';

    if (sideMenu.style.left === '0px') {
      sideMenu.style.left = '-250px'; // 메뉴를 숨김
      containerFluid.style.paddingLeft = '0rem';
    } else {
      sideMenu.style.left = '0px'; // 메뉴를 보임
      containerFluid.style.paddingLeft = '9rem';
    }
  }

  clickSideMenu(evt) {
    const evtTarget = evt.target;
    const menuType = evtTarget.getAttribute('menuType');

    this.switchToSideBarMenuType(menuType);

    const sideMenuList = evtTarget.closest('ul').querySelectorAll('li > a');

    if (sideMenuList != null && sideMenuList) {
      sideMenuList.forEach(elem => {
        const _menuType = elem.getAttribute('menuType');

        if (_menuType === menuType) {
          elem.style.textDecoration = 'underline';
          elem.style.fontWeight = 'bold';
        } else {
          elem.style.textDecoration = '';
          elem.style.fontWeight = '';
        }
      });
    }
  }

  render(){
    return(
        <div className="container-fluid py-1 px-3">
          <div className="left-div">
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item d-flex align-items-center">
                <div className="d-flex" style={{position: 'fixed'}}>
                  <a className="ms-cursor-over" onClick={(e) => {
                    this.reqHome();
                  }}>{this.getIconElement('', IconHome)}</a>
                  <a className="ms-cursor-over" onClick={(e) => {
                      this.reqSideMenu();
                  }}>{this.getIconElement('', IconMenu)}</a>
                </div>
              </li>
            </ul>
          </div>

          <nav id="sideMenu" className="side-menu" style={{transition: '0.5s', left: '0px'}}>
            <ul id="sideMenuList">
              <li><a className="ms-cursor-over font-color-ffffff" menuType="notice" onClick={(e) => {
                      e.preventDefault();
                      this.clickSideMenu(e);
                  }}>공지사항</a></li>
              <li><a className="ms-cursor-over font-color-ffffff" menuType="settle" onClick={(e) => {
                      e.preventDefault();
                      this.clickSideMenu(e);
                  }}>정산내역</a></li>
            </ul>
          </nav>

          <nav aria-label="breadcrumb">
            {/* 사옹하지 않음
           <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
            <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Dashboard</li>
          </ol>
          <h6 class="font-weight-bolder mb-0">Dashboard</h6>
          */}
          </nav>
          <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
            </div>
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item d-flex align-items-center">
                <span className='px-2'><strong>{this.rightsHolders.name}</strong>({this.rightsHolders.loginId})</span>
                <a className="ms-cursor-over" onClick={(e) => {
                  this.changePassword('layoutNav')
                }}>{this.getIconElement('', IconPersonGear)}</a>
                <a className="ms-cursor-over" onClick={(e) => {
                  this.logout(e)
                }}>{this.getIconElement('', IconLogOut)}</a>
              </li>
            </ul>
            {/*}
          <div className="dropdown">
			<a id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
				회원정보
			</a>
          	<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          		<li><a className="dropdown-item" >비밀번호 변경</a></li>
          		<li><a className="dropdown-item" >로그아웃</a></li>
          	</ul>
          </div>*/}
          </div>
        </div>
    );
  }
}
